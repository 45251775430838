<template>
  <ion-app>
        <ion-header class="topsection ion-no-border">
            <headerSection></headerSection>
        </ion-header>

        <ion-router-outlet id="main-content" :key="$route.fullPath"></ion-router-outlet>


        <!--<div style="width:100%; background-color: #9999CC; text-align: center;position: relative;bottom:0;">
            Copyright &copy; 2021 Gmina Tuczna<br />
            <span style="font-size: 0.7em;">
                Json: <b>{{ this.$store.state.conn.page }}</b>
            &ensp;&bull;&ensp;
            Conn: <b>{{ this.$store.state.conn.info.connected }}</b>
            &ensp;&bull;&ensp;
            Type: <b>{{ this.$store.state.conn.info.connectionType }}</b>
            &ensp;&bull;&ensp;
            From: <b>{{ this.$store.state.conn.from }}</b>
            </span>
        </div>-->
  </ion-app>
</template>
<style scoped>

ion-header.topsection{
  height: auto;
  min-height: 50px;
}
</style>

<script>
import {
    IonApp,
    IonHeader,
    //IonContent,
    IonRouterOutlet,
  // menuController
} from '@ionic/vue';
import {defineComponent} from 'vue';
import headerSection from "@/sections/headerSection";
import "@/theme/app.css";

export default defineComponent({
  components: {
    IonApp,
    IonHeader,
    //IonContent,
    IonRouterOutlet,
    headerSection,
  },

});
</script>
