<template>
    <ion-grid class="ion-no-padding ion-no-margin">
        <ion-row class="container ion-margin-vertical">
            <ion-col size="12" class="filter-cat ion-margin-vertical" v-if="categories">
                <router-link to="/miejsca/"><ion-button :color="(this.$route.path === '/miejsca/' || this.$route.path === '/miejsca') ? 'greengradient' : 'light'"  shape="round">Wszystkie ({{ categories.total }})</ion-button></router-link>
                <template v-for="item in categories.categories" :key="item.id">
                        <router-link :to="`/miejsca/kategorie/${item.slug}`" v-if="item.items_count > 0"><ion-button :color="currColor(item.slug)" shape="round">{{ item.title }} ({{ item.items_count }})</ion-button></router-link>
                </template>

            </ion-col>
            <ion-col size="12"><ion-item lines="none" class="ion-text-right toggle-filter" @click="toggleFilter()"><ion-label>Zaawansowane filtry</ion-label><ion-icon class="ion-margin-start" :icon="filterOutline"></ion-icon></ion-item></ion-col>
        </ion-row>
        <section class="filter-search ion-no-margin" style="display: none">
            <ion-row class="container">
                <ion-col size-lg="5" size="12">
                    <ion-item>
                        <ion-input class="searchtxt" type="text" :value="searchTxt" @ionChange="searchTxt = $event.target.value; passData('searchInput', searchTxt)" placeholder="Napisz czego szukasz" fill="light"></ion-input>
                    </ion-item>
                </ion-col>
                <ion-col size-lg="5" size="12">
                    <ion-item>
                        <ion-label>Gmina</ion-label>
                        <ion-select placeholder="Wybierz" :value="null" @ionChange="setCommunity = $event.target.value; passData('community', setCommunity)">
                            <ion-select-option :value="null" selected>Wszystkie gminy</ion-select-option>
                            <template v-for="(item, i) in availableCommunities" :key="i">
                                <ion-select-option :value="item[0]">{{ item[1] }}</ion-select-option>
                            </template>
                        </ion-select>
                    </ion-item>
                </ion-col>
                <ion-col size-lg="2" size="12">
                    <ion-item>
                        <ion-label>Sortuj</ion-label>
                        <ion-select placeholder="od A-Z" :value="sortby" @ionChange="sortby = $event.target.value;passData('sortby', sortby)">
                            <ion-select-option value="az">od A-Z</ion-select-option>
                            <ion-select-option value="popular">Najpopularniejsze</ion-select-option>
                        </ion-select>
                    </ion-item>
                </ion-col>
            </ion-row>
        </section>

    </ion-grid>
</template>

<style scoped>
.filter-cat ion-button{
    font-size: 0.75em;
    font-weight: 400;
}
section.filter-search{
    background: var(--ion-color-greengradient);
    padding:40px 0;
}
.filter-search ion-input{
    --background: #fff;
    --padding-start: 28px;
    --padding-end: 28px;
    border-radius: 30px;
    font-size:14px;
}
.filter-search ion-item{
    border-radius: 30px;
    font-size:14px;
}
.toggle-filter, .toggle-filter ion-icon{
    color:#3fa039;
    cursor:pointer;
}
ion-item.toggle-filter{
    --background: transparent;
    --border: none;
}
.filter-search ion-button{
    height:48px;
}
.filter-search ion-row{
    --ion-grid-column-padding: 5px;
    --ion-grid-column-padding-xs: 5px;
    --ion-grid-column-padding-sm: 5px;
    --ion-grid-column-padding-md: 5px;
    --ion-grid-column-padding-lg: 5px;
    --ion-grid-column-padding-xl: 5px;
}
@media screen and (max-width: 991px) {
    section.filter-search{
        padding:30px 15px;
    }
    .filter-search ion-item{
        margin-bottom:15px;
    }


}
body.contrast .filter-search ion-input{
    --background: #1e1e1e;
    --color:#fff;
}
</style>

<script>

import {IonGrid, IonRow, IonCol, IonIcon, IonButton, IonInput, IonSelect, IonLabel } from '@ionic/vue';
import {defineComponent} from 'vue';
import {categories} from '../../api/modules/place'
import { searchOutline, filterOutline } from "ionicons/icons";


export default defineComponent({
    props:{
        availableCommunities:{
            type: Object
        }
    },
    name: 'ObjectFilterSearch',
    components: {
        IonGrid, IonRow, IonCol, IonIcon, IonButton, IonInput, IonSelect, IonLabel
    },
    data(){
        return{
            searchTxt: '',
            setCommunity: '',
            categories:[],
            sortby:'',
        }
    },
    setup(){
        return{
            searchOutline, filterOutline
        }
    },
    mounted(){
      categories().then((response) => {
            if(response.data.status == 'success'){
                this.categories = response.data.data;
            }
            else{
                alert('error with categories api')
            }
      });
    },
    methods:{
        toggleFilter(){
            let filterBox = document.querySelector('.filter-search');
            return (filterBox.style.display === 'none') ? filterBox.style.display = 'block' : filterBox.style.display = 'none';
        },
        passData(ev, value){
            this.$emit(ev, value)
        },
        currColor(i) {
            return (this.$route.path === ('/miejsca/kategorie/' + i) ? 'greengradient' : 'light');
        }
    },

});

</script>
