import NewsCategories from "../../modules/News/NewsCategories";
import NewsListing from "../../modules/News/NewsListing";
import NewsShow from "../../modules/News/NewsShow";

export default [
    {
        path: '/aktualnosci/kategorie/:slug',
        name: 'news.listing.category',
        component: NewsListing,
    },
    {
        path: '/aktualnosci/kategorie',
        name: 'news.categories',
        component: NewsCategories,
    },
    {
        path: '/aktualnosci/:slug',
        name: 'news.show',
        component: NewsShow,
    },
    {
        path: '/aktualnosci',
        name: 'news.listing',
        component: NewsListing,
    }
]
