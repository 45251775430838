import Model from '../model'

class Message extends Model {

    defaults() {
        return {
            content: ''
        }
    }

    post() {
        return {
            content: this.content
        }
    }
}

export default Message
