<template>
    <template v-if="blocks.length">

        <template v-for="component in components" :key="component.id">
            <component :is="component.object" :content="component.content || {}"></component>
        </template>

    </template>
</template>

<script>

import {defineComponent, defineAsyncComponent} from 'vue';

export default defineComponent({
    name: 'BlockLoader',
    props: {
        blocks: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    computed: {
        components() {
            let copy = this.blocks.filter(block => block.type !== 'audioFile') || [];
            return copy.sort((a, b) => a.position - b.position).map((block) => {
                block.object = defineAsyncComponent(() => import('../blocks/' + block.type));
                return block;
            });
        }
    },
});

</script>
