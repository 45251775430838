import {createStore} from 'vuex';

const conn = {
    state: {
        page: null,
        from: 'unknown',
        info: {},
    },
    mutations: {
        setPage(state, value) {
            state.page = value;
        },
        setFrom(state, value) {
            state.from = value;
        },
        setInfo(state, value) {
            state.info = value;
        }
    },
    actions: {
        resolvePage(store, value) {
            store.commit('setPage', value.config.url);

            if(value.status === 200 && value.request.fromCache === true) {
                store.commit('setFrom', 'cache');
            } else if(value.status === 200 && value.request.fromCache === undefined) {
                store.commit('setFrom', 'network');
            } else {
                store.commit('setFrom', 'unknown');
            }
        }
    }
}

const store = createStore({
    modules: {
        conn: conn
    }
});

export default store;
