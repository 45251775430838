<template>
            <GMapMap
                :center="{lat: 0, lng: 0}"
                :zoom="8"
                :options="{
                   gestureHandling: 'cooperative',
                   zoomControl: true,
                   mapTypeControl: true,
                   scaleControl: false,
                   streetViewControl: false,
                   rotateControl: false,
                   fullscreenControl: true,
                   disableDefaultUi: false
                 }"
                map-type-id="terrain"
                ref="googleMapObject"
                class="masked"
            >
                <GMapCluster
                    :minimumClusterSize="200"
                    class="cluster"
                    :styles="[
                          {
                            'url': '/assets/img/m1.png',
                            height:53,
                            width:53
                          }
                    ]"
                >
                    <GMapMarker
                        v-for="(marker, index) in mapMarkers"
                        :key="index"
                        :position="marker.location"
                        :clickable="true"
                        :draggable="false"
                        @click="openInfo(marker)"
                    >
                        <GMapInfoWindow class="opened-window" :opened="marker.opened">
                            <img :src="marker.img" :alt="marker.title" />
                            <h6>{{ marker.name }}</h6>
                            <table>
                                <tr class="address" v-if="marker.address"><td><ion-icon :icon="locationOutline"></ion-icon></td><td>{{ marker.address}}<br>{{ marker.postal }}
                                    {{ marker.locality }}</td></tr>
                                <tr v-if="marker.phone"><td><ion-icon :icon="callOutline"></ion-icon></td><td>{{ marker.phone }}</td></tr>
                                <tr v-if="marker.email"><td><ion-icon :icon="mailOutline"></ion-icon></td><td><a :href="'mailto:' + marker.email" :title="'mail ' + marker.title">{{ marker.email }}</a></td></tr>
                                <tr v-if="marker.website"><td><ion-icon :icon="globeOutline"></ion-icon></td><td><a :href="marker.website" :title="marker.title" target="_blank">{{ marker.website }}</a></td></tr>
                            </table>
                        </GMapInfoWindow>
                    </GMapMarker>
                </GMapCluster>
            </GMapMap>




</template>

<script>

import { IonIcon} from '@ionic/vue';
import {defineComponent} from 'vue';
import {locationOutline, callOutline, mailOutline, globeOutline } from "ionicons/icons";

export default defineComponent({
    name: 'ContactMap',
    props:{
        content: {
            type: Object
        }
    },
    components: {

        IonIcon
    },
    setup(){
        return{
            locationOutline, callOutline, mailOutline, globeOutline
        }
    },
    mounted(){
      this.resolveMap();
    },
    data() {
        return {
            params: this.$route.params,
            mapMarkers: [],
        }
    },
    methods: {
        resolveMap() {
            if(!window.google){
                return true;
            }
            let bounds = new window.google.maps.LatLngBounds();

            this.mapMarkers = this.content.filter((item) => {
                return item;
            }).map((item) => {
                if (item.location && item.location.lat && item.location.lng) {
                    const nbLat = Number(item.location.lat);
                    const nbLng = Number(item.location.lng);
                    if (nbLat && nbLng) {
                        const locForApi = new window.google.maps.LatLng(Number(item.location.lat), Number(item.location.lng));
                        bounds.extend(locForApi);
                    }
                }
                return {
                    name: item.title,
                    address: item.address,
                    postal: item.postal,
                    locality: item.locality,
                    phone: item.phone,
                    email: item.email,
                    website: item.website,
                    img: item.main_image.src,
                    location: {
                        lat: Number(item.location.lat),
                        lng: Number(item.location.lng),
                    },
                    opened: false
                }
            });
            this.$nextTick(() => {
                this.$refs.googleMapObject.fitBounds(bounds);
                this.$refs.googleMapObject.panToBounds(bounds);
            });
        },
        openInfo(marker) {
            this.mapMarkers.map((item) => item.opened = false);
            marker.opened = true;
        },
    },
    watch:{
        content: function(){
            this.resolveMap();
        }
    }
});

</script>

<style scoped>
.opened-window img{
    width:80px;
    float:left;
    margin: 0 10px 10px 0;
}
div.vue-map-container {
    width: 100% !important;
    height: 600px !important;
    margin-top:-30px;
}

.masked {
    mask-image: url("../../../public/assets/img/maskbigmap.svg");
    mask-repeat: no-repeat;
    mask-size: cover;
    mask-position: top left;
}
.opened-window table{
    display: block;
    width: 320px;
}
.opened-window td:first-child{
    vertical-align: top;
    padding-right:15px;
}
.opened-window td{
    padding-bottom: 5px;
    letter-spacing: 0.5px;
}
.opened-window ion-icon{
    color:#3e9355;
    font-size:1.2em
}
.opened-window a{
    color:#3e9355;
    text-decoration: none;
}

</style>
