import { Network } from "@capacitor/network";
import store from '../store';

const connectionCheck = async () => {
    const connectionStatus = await Network.getStatus();
    store.commit('setInfo', connectionStatus);
    return connectionStatus.connected === true;
}

export { connectionCheck };
