import { Storage } from '@ionic/storage';
import { mapObject } from 'axios-cache-adapter/src/utilities'

class IonicStorage {

    constructor () {
        (async () => {
            this.store = new Storage();
            await this.store.create();
        })();
    }

    async getItem (key) {
        return await this.store.get(key) || null;
    }

    async setItem (key, value) {
        void await this.store.set(key, value);
        return value;
    }

    async removeItem (key) {
        return await this.store.remove(key);
    }

    async clear () {
        return await this.store.clear();
    }

    async length () {
        return await this.store.length();
    }

    iterate (fn) {
        return Promise.all(mapObject(this.store, fn))
    }
}

export function getStoreInstance() {
    return new IonicStorage();
}
