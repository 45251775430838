import EventListing from "../../modules/Event/EventListing";
import EventShow from "../../modules/Event/EventShow";

export default [
    {
        path: '/wydarzenia/:slug',
        name: 'event.show',
        component: EventShow,
    },
    {
        path: '/wydarzenia',
        name: 'event.listing',
        component: EventListing,
    }
]
