<template>
    <arms-carousel></arms-carousel>
    <ion-grid class="footer-section ion-no-padding ion-no-margin">
        <ion-row class="ion-justify-content-between container">
            <ion-col size="12" size-lg="7" class="justify-content-center">
                <ul>
                    <li v-for="item in footerMenu" :key="item.position"><router-link :to="item.path">{{ item.name}}</router-link></li>
                </ul>
            </ion-col>
            <ion-col size="12" size-lg="2"><img src="/assets/img/logo.svg" alt="logo aktywne pogranicze" /></ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-between copy container">
            <ion-col size="12"><p>Copyright © Aktywne Pogranicze</p></ion-col>
        </ion-row>
        <ion-row class="container ion-text-center ue-logos ion-padding-vertical ion-justify-content-center">
                <ion-col size-lg="3" size="12">
                    <img src="/assets/img/FE.svg">
                </ion-col>
                <ion-col size-lg="3" size="12">
                    <img src="/assets/img/RP.svg">
                </ion-col>
                <ion-col size-lg="3" size="12">
                    <img src="/assets/img/Lubelskie.png">
                </ion-col>
                <ion-col size-lg="3" size="12">
                    <img src="/assets/img/UE.svg">
                </ion-col>
        </ion-row>
    </ion-grid>
</template>

<style scoped>
.ue-logos{
    padding-top:30px;
}
.ue-logos img{
    height:40px;
}
.footer-section{
    background-image: url("../../public/assets/img/paperbg.jpg");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    padding-top:80px;

}
ion-row{
    align-items: center;
}
ul{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:0;
    margin:0;
}
li{
    list-style-type: none;
    margin:0 10px;
}
li:first-child{
    margin:0;
}
li a{
    color:#096d9d;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 18px;
    white-space: nowrap;
}
.copy{
    font-size:0.7em;
    color:#096d9d;
}
@media screen and (max-width: 991px){
    .footer-section{
        background-size: auto;
        height: auto;
    }
    ion-col{
        text-align: center;
    }
    img{
        width: 250px;
        margin: 0 auto 40px;
    }
    .footer-section ion-row{
        flex-direction: column-reverse;
    }
    .footer-section ul{
        flex-direction: column;
    }
    .footer-section li{
        text-align: center;
        margin-bottom:15px;
    }
    .copy{
        text-align: center;
    }
}
body.contrast .footer-section{
    background-image: none;
}
body.contrast li a, body.contrast .copy{
    color:#fff;
}
</style>

<script>
import { IonGrid, IonRow, IonCol } from '@ionic/vue';

import {defineComponent} from 'vue';
import menuResolver from "../helpers/structure";
import ArmsCarousel from "./Home/armsCarousel";


export default defineComponent({
    components: {
        ArmsCarousel,
        IonGrid,
        IonRow,
        IonCol,
    },
    data(){
      return{
          footerMenu: [],
      }
    },
    mounted: function() {
        menuResolver('footer-menu').then((menu) => {
            this.footerMenu = menu;
        });
    }
});
</script>
