import Model from '../model'

class Contact extends Model {

    defaults() {
        return {
            name: '',
            email: '',
            phone: '',
            content: '',
            confirm: false,
            file: null,
        }
    }

    post() {
        let formData = new FormData();

        formData.append('name', this.name);
        formData.append('email', this.email);
        formData.append('phone', this.phone);
        formData.append('content', this.content);
        formData.append('confirm', this.confirm);

        return formData;
    }
}

export default Contact
