<template>
    <ion-grid class="mobileapp-section ion-margin-vertical">
        <ion-row class="container ion-justify-content-between texts">
            <ion-col size="6" size-lg="3">
                <ion-img src="/assets/img/downloadapptxt.svg"></ion-img>
            </ion-col>
            <ion-col size="6" size-lg="4" class="ion-text-right">
                <ion-img src="/assets/img/mobileapptxt.svg"></ion-img><br>
            </ion-col>
        </ion-row>
        <ion-row class="container">
            <ion-col size="12" class="download-buttons">
                <ion-img src="/assets/img/appstore-badge.svg" class="ion-margin-end"></ion-img>
                <ion-img src="/assets/img/google-play-badge.svg"></ion-img>
            </ion-col>
        </ion-row>
    </ion-grid>
</template>

<style scoped>
html.plt-capacitor .mobileapp-section, html.plt-mobile .mobileapp-section{
    display: none;
}
.mobileapp-section{
    background-image: url("../../../public/assets/img/mobileappbg.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
    padding:150px 0;

}
.download-buttons{
    display: flex;
    flex-direction: row;
    justify-content: end;
}
.download-buttons ion-img{
    height:67px;
}
@media screen and (max-width: 991px){
    .mobileapp-section{
        background-size: 100% auto;
        background-position: -21vw 2vw;
        padding:0;
    }
    .mobileapp-section > ion-row{
        flex-direction: row;
        align-content: end;
        justify-content: flex-end !important;
    }
    .texts ion-col{
        margin-right: 15px;
        margin-bottom: 15px;
    }
    .download-buttons{
        justify-content: center;
    }
}
</style>

<script>
import { IonGrid, IonRow, IonCol, IonImg } from '@ionic/vue';

import {defineComponent} from 'vue';


export default defineComponent({
    components: {
        IonGrid,
        IonRow,
        IonCol,
        IonImg
    },
});
</script>
