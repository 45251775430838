import axios from 'axios'
import store from '../store';

import { setupCache } from 'axios-cache-adapter'
import { getStoreInstance } from './storage';
import { connectionCheck } from "./connection";

const apiDomain = process.env.VUE_APP_API_DOMAIN;

const cache = setupCache({
    maxAge: 1000,
    store: getStoreInstance(),
    readOnError: true,
    clearOnStale: false
    //exclude: { filter: () => connectionCheck() }
});

const service = axios.create({
    baseURL: apiDomain + '/api',
    adapter: cache.adapter
})

service.interceptors.request.use(
    config => {
        config.headers['Accept'] = 'application/json';
        // if (store.getters.token) {
        //     config.headers['Authorization'] = 'Bearer ' + getToken()
        // }
        void connectionCheck();
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        void store.dispatch('resolvePage', response);
        return response;
    },
    error => {
        return Promise.reject(error)
    }
)

export default service
