import PageListing from "../../modules/Page/PageListing";
import PageShow from "../../modules/Page/PageShow";

export default [
    {
        path: '/strony',
        name: 'page.listing',
        component: PageListing,
    },
    {
        // page.show should be the last one to define,
        // as it is wildcard for the whole structure.
        path: '/:slug(.*)',
        name: 'page.show',
        component: PageShow,
    }
]
