import TicketCreate from "../../modules/Ticket/TicketCreate";
import TicketShow from "../../modules/Ticket/TicketShow";

export default [
    {
        path: '/zgloszenia',
        name: 'ticket.create',
        component: TicketCreate,
    },
    {
        path: '/zgloszenia/:id(.*)/:hash(.*)',
        name: 'ticket.show',
        component: TicketShow,
    }
]
