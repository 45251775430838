import Error401 from "../../views/Error/Error401";
import Error403 from "../../views/Error/Error403";
import Error404 from "../../views/Error/Error404";

export default [
    {
        path: '/401',
        name: 'error.401',
        component: Error401,
    },
    {
        path: '/403',
        name: 'error.403',
        component: Error403,
    },
    {
        path: '/404',
        name: 'error.404',
        component: Error404,
    },
]
