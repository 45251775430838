<template>
    <ion-grid class="ion-no-padding ion-no-margin">
        <ion-row class="container ion-margin-vertical">
            <ion-col size="12" class="filter-cat ion-margin-vertical" v-if="categories">
                <ion-button @click="filter = 0; passData('filters', 0)" :color="toggleColor(0)" shape="round">Wszystkie ({{ total }})</ion-button>
                <template v-for="item in categories" :key="item.slug">
                    <ion-button v-if="item.items_count > 0" @click="filter = item.slug; passData('filters', item.slug)" :color="toggleColor(item.slug)" :data-name="item.slug" shape="round">{{ item.title }} ({{ item.items_count }})</ion-button>
                </template>
            </ion-col>
            <ion-col size="12"><ion-item lines="none" class="ion-text-right toggle-filter" @click="toggleFilter()"><ion-label>Zaawansowane filtry</ion-label><ion-icon class="ion-margin-start" :icon="filterOutline"></ion-icon></ion-item></ion-col>
        </ion-row>
        <section class="filter-search ion-no-margin" style="display: none">
            <ion-row class="container">
                <ion-col size-lg="6" size="12">
                    <ion-item>
                        <ion-input class="searchtxt" type="text" :value="searchTxt" @ionChange="searchTxt = $event.target.value; passData('search', searchTxt)" placeholder="Napisz czego szukasz" fill="light"></ion-input>
                    </ion-item>
                </ion-col>
                <ion-col size-lg="6" size="12">
                    <ion-item>
                        <ion-label>Gmina</ion-label>
                        <ion-select placeholder="Wybierz" :value="setCommunity" @ionChange="setCommunity = $event.target.value; passData('community',$event.target.value); setCommunity = $event.target.value">
                            <ion-select-option :value="null" selected>Wszystkie gminy</ion-select-option>
                            <template v-for="(item,i) in availableCommunities" :key="i">
                                <ion-select-option :value="item[0]">{{ item[1] }}</ion-select-option>
                            </template>
                        </ion-select>
                    </ion-item>
                </ion-col>

            </ion-row>
        </section>

    </ion-grid>
</template>

<style scoped>
.filter-cat ion-button{
    font-size: 0.75em;
    font-weight: 400;
}
section.filter-search{
    background: var(--ion-color-greengradient);
    padding:40px 0;
}
.filter-search ion-input{
    --background: #fff;
    --padding-start: 28px;
    --padding-end: 28px;
    border-radius: 30px;
    font-size:14px;
}
.filter-search ion-item{
    border-radius: 30px;
    font-size:14px;
}
.toggle-filter, .toggle-filter ion-icon{
    color:#3fa039;
    cursor:pointer;
}
ion-item.toggle-filter{
    --background: transparent;
    --border: none;
}
.filter-search ion-button{
    height:48px;
}
.filter-search ion-row{
    --ion-grid-column-padding: 5px;
    --ion-grid-column-padding-xs: 5px;
    --ion-grid-column-padding-sm: 5px;
    --ion-grid-column-padding-md: 5px;
    --ion-grid-column-padding-lg: 5px;
    --ion-grid-column-padding-xl: 5px;
}
@media screen and (max-width: 991px) {
    section.filter-search{
        padding:30px 15px;
    }
    .filter-search ion-item{
        margin-bottom:15px;
    }


}

body.contrast ion-input{
    --background:#1e1e1e
}
</style>

<script>

import {IonGrid, IonRow, IonCol, IonIcon, IonButton, IonInput, IonSelect, IonSelectOption, IonItem, IonLabel } from '@ionic/vue';
import {defineComponent} from 'vue';
import { searchOutline, filterOutline } from "ionicons/icons";
import {categories as catPlace }  from "../../api/modules/place";
import {categories as catInfrastructure } from "../../api/modules/infrastructure";


export default defineComponent({
    props: {
      mapPins:{
          type: Object,
      }
    },
    name: 'ObjectFilterSearch',
    components: {
        IonGrid, IonRow, IonCol, IonIcon, IonButton, IonInput, IonSelect, IonLabel, IonSelectOption, IonItem
    },
    data(){
        return{
            searchTxt: '',
            setCommunity: '',
            availableCommunities: [],
            content:[],
            categories: [],
            filter: 0
        }
    },
    setup(){
        return{
            searchOutline, filterOutline
        }
    },
    mounted(){
        Promise.all([catPlace(), catInfrastructure()])
            .then((response) => {
                    let short = response.map((val) =>{
                        return {
                            cat: val.data.data.categories,
                            total: val.data.data.total
                        }
                    });
                    if (response[0].data.status === 'success' && response[1].data.status === 'success') {
                        this.categories = [].concat(short[0].cat, short[1].cat) || [];
                        this.total = short[0].total + short[1].total;
                    } else {
                        alert('There was a problem with loading your content.')
                    }
                }
            );

    },
    methods:{
        toggleFilter(){
            let filterBox = document.querySelector('.filter-search');
            return (filterBox.style.display === 'none') ? filterBox.style.display = 'block' : filterBox.style.display = 'none';
        },
        passData(ev, value){
            this.$emit(ev, value)
        },
        toggleColor(item){
            return ((item === 0 && this.filter === 0) ? 'greengradient' : ((this.filter === item) ? 'greengradient' : 'light'));
        },
    },
    watch:{
        mapPins: function(){
            this.availableCommunities = new Map([...this.mapPins.map((el) => {
                return [el.location.communityId, el.location.communityTitle]

            })].sort((a,b)=>(a[1] < b[1]) ? -1 : ((a[1] > b[1]) ? 1 : 0)));
        }
    }

});

</script>
