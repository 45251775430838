<template>
    <div class="place" :style="objectImg(item.main_image)"
         @click="this.$router.push({path: `/infrastruktura/${item.slug}/`})">
        <div class="desc">
            <div class="frontdesc">
                <div class="elwrapper">
                    <p class="name">{{ item.title }}</p>
                    <p class="info"><span class="city">{{ item.community.title }}</span><a v-if="item.location.lat && item.location.lng" @click="openMap(item.location.lat, item.location.lng)" class="showmap">
                        <ion-icon :icon="location"></ion-icon>
                        Pokaż na mapie</a></p>
                </div>
            </div>
            <div class="backdesc">
                <div class="rating">Opinie:
                    <star-rating-display :avg="item.comments.scores_avg"></star-rating-display>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import {IonIcon} from '@ionic/vue';
import {defineComponent} from 'vue';
import {location, star, starHalfOutline, starOutline} from "ionicons/icons";
import StarRatingDisplay from "../../views/StarRatingDisplay";

export default defineComponent({
    components: {
        StarRatingDisplay,
        IonIcon,
    },
    props: {
        item: {type: Object}
    },
    methods: {
        objectImg(main_image) {
            if (main_image && main_image['src']) {
                let image_url = this.$imageUrl(main_image, {'w': 860});
                return `background-image: url('${image_url}');`
            }
        },
        openMap(lat, lng) {
            window.open('https://www.google.com/maps/dir/?api=1&destination=' + lat + ',' +  lng);
        }
    },
    setup() {
        return {
            location, star, starOutline, starHalfOutline
        }
    }
});

</script>
