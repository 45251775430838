<template>
    <ion-page>
        <ion-content>
            <section class="objects">
                <h1 class="ion-text-center">Miejsca</h1>
                <place-filter-search @sortby="sortby($event)" @searchInput="setSearch($event)" @community="setCommunity($event)"
                                     :availableCommunities="availableCommunities"></place-filter-search>
                <ion-grid class="ion-no-margin ion-no-padding">
                    <ion-row>
                        <template v-if="loaded === false">
                            <ion-col size="12" class="ion-text-center">
                                <ion-spinner name="lines"></ion-spinner>
                            </ion-col>
                        </template>
                        <template v-if="contentFiltered.length === 0 && loaded === true">
                            <ion-col size="12" class="ion-text-center">
                                <h1 class="ion-text-center">Nie znaleziono</h1>
                            </ion-col>
                        </template>
                        <ion-col v-for="item in contentFiltered" :key="item.id" size-lg="4" size="12">
                            <place-item :item="item"></place-item>
                        </ion-col>
                    </ion-row>
                </ion-grid>
                <footer-section></footer-section>
            </section>
        </ion-content>
    </ion-page>
</template>


<script>

import {IonContent, IonPage, IonSpinner} from '@ionic/vue';
import {defineComponent} from 'vue';
import {listing} from "@/api/modules/place";
import PlaceItem from "./PlaceItem";
import FooterSection from "../../sections/footerSection";
import PlaceFilterSearch from "./PlacesFilterSearch";

export default defineComponent({
    name: 'ObjectListing',
    components: {
        PlaceFilterSearch,
        FooterSection,
        PlaceItem,
        IonContent,
        IonPage,
        IonSpinner
    },
    data() {
        this.setTitle('Miejsca');
        return {
            params: this.$route.params,
            content: [],
            availableCommunities: [],
            filterText: '',
            community: null,
            loaded:false,
        }
    },
    mounted() {
        let slug = this.params.slug;

        listing(slug).then((response) => {
            this.loaded = false;
            if (response.data.status === 'success') {
                this.content = response.data.data || [];
                this.availableCommunities = new Map([...this.content.map((el) => {
                    this.loaded = true;
                    return [el.community.id, el.community.title]
                })].sort((a, b) => (a[1] < b[1]) ? -1 : ((a[1] > b[1]) ? 1 : 0)));
            } else {
                alert('There was a problem with loading your content.')
            }
        });


    },
    computed: {

        contentFiltered() {
            return this.content.filter(item => {
                let search = item.title.toLowerCase().includes(this.filterText.toLowerCase());
                let iscommunity = true;
                if (this.community) {
                    iscommunity = (item.community.id === this.community);
                }

                return search && iscommunity
            })
        },
    },
    methods: {
        setSearch(searchTxt) {
            this.filterText = searchTxt;
        },
        setCommunity(val) {
            this.community = val;
        },
        sortby(a){
          if(a === 'popular'){
              console.log('sortuje wg popularnosci');
              this.content = this.content.sort((a,b) =>{
                  return (a.comments.scores_cnt > b.comments.scores_cnt) ? -1 : ((a.comments.scores_cnt < b.comments.scores_cnt) ? 1 : 0)
              });
          }
          if(a === 'az'){
              this.content = this.content.sort((a,b)=>{
                  return (a.title > b.title) ? 1 : ((a.title < b.title) ? -1 : 0);
              });
          }
        }
    }
});

</script>
