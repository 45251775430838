import request from '../../helpers/request'

export function categories() {
    return request({
        url: `/infrastructure/categories/`,
        method: 'get',
        params: {}
    })
}

export function listing(slug) {
    return request({
        url: (slug) ? `/infrastructure/listing/${slug}` : `/infrastructure/listing/`,
        method: 'get',
        params: {}
    })
}

export function show(slug) {
    return request({
        url: `/infrastructure/show/${slug}`,
        method: 'get',
        params: {}
    })
}
