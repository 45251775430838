<template>
    <template v-for="(item,i) in stars" :key="i">
        <ion-icon :name="item"></ion-icon>
    </template>
</template>


<script>

import {defineComponent} from 'vue';
import {starOutline, starHalfOutline, star,} from "ionicons/icons";

export default defineComponent({
    name: 'StarRatingDisplay',
    setup() {
        return {
            starOutline, starHalfOutline, star
        }
    },
    components: {

    },
    props: {
        avg: {
            type: Number,
            required: true,
            default: () => {
            }
        }
    },
    data() {
        return {
            stars: [],
            rateBoxOpen: false
        }
    },
    methods: {
        starRating(score) {
            this.stars = [];
            let full = Math.floor(score);
            let empty = Math.floor(5 - score);
            for (let i = 0; i < full; i++) {
                this.stars.push('star')
            }
            if (score > full && score < full + 1) {
                this.stars.push('star-half-outline');
            }
            for (let i = 0; i < empty; i++) {
                this.stars.push('star-outline');
            }
        },
    },
    mounted() {
        this.starRating(this.avg);
    }
});

</script>
