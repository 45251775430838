import InfrastructureCategories from "../../modules/Infrastructure/InfrastructureCategories";
import InfrastructuresListing from "../../modules/Infrastructure/InfrastructuresListing";
import InfrastructureShow from "../../modules/Infrastructure/InfrastructureShow";
import InfrastructuresMap from "../../modules/Infrastructure/InfrastructuresMap";


export default [
    {
        path: '/infrastruktura/kategorie/:slug',
        component: InfrastructuresListing,
    },
    {
        path: '/infrastruktura/kategorie',
        component: InfrastructureCategories,
    },
    {
        path: '/infrastruktura/mapa/:slug',
        component: InfrastructuresMap,
    },
    {
        path: '/infrastruktura/mapa',
        component: InfrastructuresMap,
    },
    {
        path: '/infrastruktura/:slug',
        component: InfrastructureShow,
    },
    {
        path: '/infrastruktura',
        component: InfrastructuresListing,
    }
]
