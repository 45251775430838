<template>
    <swiper
        :breakpoints="swiperOptions.breakpoints"
        :slides-per-view="7"
        :spaceBetween="30"
        :freeMode="true"
        class="arms-slider"
        :navigation="true"
    >
        <swiper-slide v-for="item in arms" :key="item.id">
            <a :href="item.website" target="_blank">
                <template v-if="item.main_image">
                    <img :src="$imageUrl(item.main_image)" :alt="item.alt"/>
                </template>
            </a>
            <p>{{ item.title }}</p>
        </swiper-slide>
    </swiper>
</template>


<style scoped>
.swiper-button-next {
    color: #000;
}

.swiper {
    width: 100%;
    height: 240px;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: auto;
    height: 50px;
    object-fit: cover;
}

.swiper-slide p {
    font-size: 0.8em;
    font-weight: 400;
}
</style>
<script>
import {communityList} from '../../api/modules/community';
// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import "swiper/css/free-mode"
import "swiper/css/pagination"
import "swiper/css/navigation"


// import Swiper core and required modules
import SwiperCore, {FreeMode, Pagination} from 'swiper';

// install Swiper modules
SwiperCore.use([FreeMode, Pagination]);


export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        let arms = {};
        return {
            arms,
            swiperOptions: {
                breakpoints: {
                    320: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    770: {
                        slidesPerView: 4,
                        spaceBetween: 10
                    },
                    991: {
                        slidesPerView: 6,
                        spaceBetween: 20
                    },
                    1200: {
                        slidesPerView: 7,
                        spaceBetween: 30
                    }
                }
            }
        };
    },
    mounted() {
        communityList().then((response) => {
            if (response.data.status === 'success') {
                this.arms = response.data.data || {}
            } else {
                alert('problem with community list');
            }
        })
    }

}
</script>
