<template>
    <ion-grid class="infrastructure-section container ion-padding-vertical ion-margin-vertical">
        <ion-row class="ion-padding-vertical ion-margin-vertical">
            <ion-col class="ion-text-center ion-padding-vertical ion-margin-vertical">
                <h1 class="container">Infrastruktura</h1>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col class="ion-margin-vertical" @click="this.$router.push({'path' : '/infrastruktura'})"><div class="box"><ion-icon :icon="home"></ion-icon><h3>NOCLEGI</h3></div></ion-col>
            <ion-col class="ion-margin-vertical" @click="this.$router.push({'path' : '/infrastruktura'})"><div class="box"><ion-icon :icon="home"></ion-icon><h3>GASTRONOMIA</h3></div></ion-col>
            <ion-col class="ion-margin-vertical" @click="this.$router.push({'path' : '/infrastruktura'})"><div class="box"><ion-icon :icon="home"></ion-icon><h3>FUNDACJE<br>I STOWARZYSZENIA</h3></div></ion-col>
            <ion-col class="ion-margin-vertical" @click="this.$router.push({'path' : '/infrastruktura'})"><div class="box"><ion-icon :icon="home"></ion-icon><h3>PUNKTY<br>INFORMACYJNE</h3></div></ion-col>
            <ion-col class="ion-margin-vertical" @click="this.$router.push({'path' : '/infrastruktura'})"><div class="box"><ion-icon :icon="home"></ion-icon><h3>KAMERY<br>NA ŻYWO</h3></div></ion-col>
        </ion-row>
    </ion-grid>
</template>

<style scoped>
.box{
    width: 306px;
    height: 306px;
    background-color:#fff;
    margin:0 auto;
    border-radius: 306px;
    border:solid 1px #e6e6e6;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    text-align: center;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    cursor: pointer;

}
.box::after{
    content:'';
    position: absolute;
    width:90px;
    height:33px;
    background-image:url("../../../public/assets/img/sticker.svg");
    background-repeat: no-repeat;
    top:-10px;
    transform: rotate(90deg);
}
.box::before{
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    border-radius:306px; /*1*/
    border:10px solid transparent; /*2*/
    background:linear-gradient(45deg,white,white) border-box; /*3*/
    -webkit-mask: /*4*/
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out; /*5'*/
    mask-composite: exclude; /*5*/

}
.box:hover::before{
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    border-radius:306px; /*1*/
    border:10px solid transparent; /*2*/
    background:linear-gradient(90deg, rgba(19,123,48,1) 0%, rgba(96,196,36,1) 100%) border-box; /*3*/
    -webkit-mask: /*4*/
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out; /*5'*/
    mask-composite: exclude; /*5*/
}
.box ion-icon{
    font-size:50px;
    color:#3ca22a;
}
.box h3{
    font-weight: 300;
}
@media screen and (max-width: 991px){
    .box{
        margin-bottom:30px;
    }
}
body.contrast h3{
    color:#000;
}
</style>


<script>
import { IonGrid, IonRow, IonCol, IonIcon } from '@ionic/vue';
import { home } from 'ionicons/icons';

import {defineComponent} from 'vue';


export default defineComponent({
    components: {
        IonGrid,
        IonRow,
        IonCol,
        IonIcon
    },
    setup(){
        return{
            home
        }
    }
});
</script>
