<template>
    <ion-row class="container community-listing ion-justify-content-around ion-margin-bottom ion-padding-bottom">
        <template v-for="item in content" :key="item.id">
            <ion-col class="community-item" size-lg="4" size-md="6" size="12">
                <ion-row>
                    <ion-col size="3"><img :src="item.main_image.src" :alt="item.main_image.alt" /></ion-col>
                    <ion-col size="9">
                        <h4 class="ion-no-margin">{{ item.title }}</h4>
                        <table>
                            <tr class="address" v-if="item.address"><td><ion-icon :icon="locationOutline"></ion-icon></td><td>{{ item.address}}<br>{{ item.postal }}
                                {{ item.locality }}</td></tr>
                            <tr v-if="item.phone"><td><ion-icon :icon="callOutline"></ion-icon></td><td>{{ item.phone }}</td></tr>
                            <tr v-if="item.email"><td><ion-icon :icon="mailOutline"></ion-icon></td><td><a :href="'mailto:' + item.email" :title="'mail ' + item.title">{{ item.email }}</a></td></tr>
                            <tr v-if="item.website"><td><ion-icon :icon="globeOutline"></ion-icon></td><td><a :href="item.website" :title="item.description" target="_blank">{{ item.website }}</a></td></tr>
                        </table>
                    </ion-col>
                </ion-row>
            </ion-col>
        </template>
    </ion-row>
</template>


<style scoped>
ion-row{
    --ion-grid-column-padding: 10px;
    --ion-grid-column-padding-xs: 10px;
    --ion-grid-column-padding-sm: 10px;
    --ion-grid-column-padding-md: 10px;
    --ion-grid-column-padding-lg: 10px;
    --ion-grid-column-padding-xl: 10px;
}
.community-item ion-row{
    border: solid 1px #e5e5e5;
    height: 100%;
    background-color:#fff;
    padding:40px 15px;
    color:#6c6c6c;
    font-size:0.87em;
}
.community-item a{
    color:#6c6c6c;
    text-decoration: none;
}
.community-item a:hover{
    color:#3e9355;
}
.community-item img{
    width:85px;
}
.community-item h4{
    font-size:1.4em;
    font-weight: 600;
}
.community-item table{
    margin-top:15px;
}
.community-item td:first-child{
    vertical-align: top;
    padding-right:15px;
}
.community-item td{
    padding-bottom: 5px;
    letter-spacing: 0.5px;
}
.community-item tr.address td{
    padding-bottom:38px;
}
.community-item ion-icon{
    color:#3e9355;
    font-size:1.2em
}
body.contrast .community-item ion-row{
    background-color:#1e1e1e !important;
    border: none;
    color:#fff;
}
body.contrast .community-item ion-row a{
    color:#fff;
}


</style>

<script>

import {IonRow, IonCol, IonIcon} from '@ionic/vue';
import {defineComponent} from 'vue';
import {locationOutline, callOutline, mailOutline, globeOutline } from "ionicons/icons";

export default defineComponent({
    name: 'ContactCommunityListing',
    props:{
      content:{
          type:Object
      }
    },
    components: {
        IonRow,
        IonCol,
        IonIcon
    },
    setup(){
        return{
            locationOutline, callOutline, mailOutline, globeOutline
        }
    },
    data() {
        return {
            params: this.$route.params,
        }
    },

});

</script>
