class Model {

    constructor() {
        this.resetData();
    }

    getData() {
        return this.post();
    }

    setData(data) {
        for(const property in data) {
            this[property] = data[property];
        }
    }

    resetData() {
        this.setData(this.defaults());
    }

}

export default Model
