import request from '../../helpers/request'

export function categories() {
    return request({
        url: `/news/categories/`,
        method: 'get',
        params: {}
    })
}

export function listing(slug) {
    return request({
        url: (slug) ? `/news/listing/${slug}` : `/news/listing/`,
        method: 'get',
        params: {}
    })
}

export function show(slug) {
    return request({
        url: `/news/show/${slug}`,
        method: 'get',
        params: {}
    })
}
