import Model from '../model'

class Ticket extends Model {

    defaults() {
        return {
            name: '',
            email: '',
            phone: '',
            title: '',
            content: ''
        }
    }

    post() {
        return {
            name: this.name,
            email: this.email,
            phone: this.phone,
            title: this.title,
            content: this.content
        }
    }
}

export default Ticket
