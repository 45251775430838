import request from '../../helpers/request'

export function exampleCallForListing(slug) {
    return request({
        url: `/page/listing/`,
        method: 'get',
        params: {
            slug: slug
        }
    })
}

export function exampleCallForShow(slug) {
    return request({
        url: `/page/show/${slug}`,
        method: 'get',
        params: {}
    })
}
