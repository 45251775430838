<template>
    <ion-row>
        <ion-col size="12" class="ion-no-padding ion-no-margin">
            <div class="contact-form masked">
                <h1 class="ion-text-center ion-margin-bottom">Formularz Kontaktowy</h1>
                <div class="container">
                    <ion-row class="ion-justify-content-around">
                        <ion-col size-lg="10" size="12">

                            <template v-if="[1,2].includes(status)">
                                <ion-grid>
                                    <ion-row>
                                        <ion-col size-lg="4" size="12">
                                            <ion-item>
                                                <ion-input placeholder="Imię i nazwisko" fill="light" v-model="contact.name"></ion-input>
                                            </ion-item>
                                        </ion-col>
                                        <ion-col size-lg="4" size="12">
                                            <ion-item>
                                                <ion-input placeholder="Adres e-mail" fill="light" v-model="contact.email"></ion-input>
                                            </ion-item>
                                        </ion-col>
                                        <ion-col size-lg="4" size="12">
                                            <ion-item>
                                                <ion-input placeholder="Telefon kontaktowy" fill="light" v-model="contact.phone"></ion-input>
                                            </ion-item>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col size="12">
                                            <ion-item>
                                                <ion-textarea auto-grow="true" rows="6" v-model="contact.content"></ion-textarea>
                                            </ion-item>
                                            <ion-item class="reg" style="--background:transparent;" lines="none">
                                                <ion-label color="light">Zapoznałem się z informacją o przetwarzaniu danych osobowych</ion-label>
                                                <ion-checkbox color="light" slot="start" v-model="contact.confirm"></ion-checkbox>
                                            </ion-item>
                                            <ion-item class="reg" style="--background:transparent;" lines="none">
                                                <ion-label color="light">Chcę dołączyć plik do zgłoszenia</ion-label>
                                                <ion-checkbox color="light" slot="start" v-model="attachment"></ion-checkbox>
                                            </ion-item>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row v-if="attachment === true">
                                        <ion-col size-lg="4" size="12">
                                            <ion-item>
                                                <ion-input type="file" fill="light" v-model="contact.file" ref="contactFile"></ion-input>
                                            </ion-item>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col size="12" class="ion-text-center">
                                            <ion-button color="light" fill="outline" shape="round" @click="send"
                                                        :disabled="[2,3].includes(status)">Wyślij wiadomość</ion-button>
                                        </ion-col>
                                    </ion-row>
                                </ion-grid>
                            </template>

                            <template v-else>
                                <h5 class="ion-text-center" style="color:#fff;">Twoja wiadomość została wysłana</h5>
                            </template>

                        </ion-col>
                    </ion-row>

                </div>
            </div>
        </ion-col>
    </ion-row>
</template>
<style scoped>
.contact-form{
    background-image: linear-gradient(90deg, rgba(19, 123, 48, 0.52) 0%, rgba(96, 196, 36, 0.73) 100%), url("../../../public/assets/slider/1.jpg");
    background-size:cover;
    color:#fff;
    padding:50px 0 80px;
}

.contact-form h1{
    color:#fff;
}
.masked {
    mask-image: url("../../../public/assets/img/contactformmask.svg");
    mask-repeat: no-repeat;
    mask-size: cover;
    mask-position: top left;
}
ion-input{
    --background: #fff;
    --padding-start: 28px;
    --padding-end: 28px;
    border-radius: 30px;
    font-size:14px;
}
ion-item{
    border-radius: 30px;
    font-size:14px;
}
ion-item.reg ion-label{
    text-overflow: initial;
    white-space: break-spaces;
}
body.contrast h1{
    color:#000;
}
body.contrast ion-input{
    --background: #1e1e1e;
}
</style>

<script>

import {IonGrid, IonRow, IonCol, IonInput, IonItem, IonTextarea, IonCheckbox} from '@ionic/vue';
import {defineComponent} from 'vue';
import {submit} from "../../api/modules/contact";
import Contact from "../../models/modules/contact";

export default defineComponent({
    name: 'ContactForm',
    components: {
        IonGrid,
        IonRow,
        IonCol,
        IonInput,
        IonItem,
        IonTextarea,
        IonCheckbox
    },
    data() {
        return {
            contact: new Contact,
            status: 1,
            attachment: false,
        }
    },
    methods: {
        send() {
            if(!this.contact.confirm) {
                alert('Aby przesłać formularz należy zapoznać się z informacją o przetwarzaniu danych osobowych');
            } else {
                this.status = 2;
                let formData = this.contact.getData();
                if(this.$refs.contactFile) {
                    formData.append('file', this.$refs.contactFile.$el.firstChild.files[0]);
                }
                submit(formData).then((response) => {
                    if (response.data.status === 'success') {
                        this.contact.resetData();
                        this.status = 3;
                    } else {
                        alert('There was a problem with loading your content.')
                    }
                });
            }
        }
    },
});

</script>
