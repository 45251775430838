<template>
    <ion-page>
        <ion-content :scroll-events="true" id="mapContent">
            <section class="objects">
                <h1 class="ion-text-center" id="map-header">Mapa Aktywnego Pogranicza</h1>
                <map-filter-search @search="setSearch($event)" @community="setCommunity($event)" @filters="setFilter($event)" :mapPins="mapMarkers"></map-filter-search>
                <ion-grid class="ion-no-margin ion-no-padding">
                    <ion-row>
                        <ion-col size="12" class="ion-no-margin ion-no-padding">
                            <GMapMap
                                :center="{lat: 0, lng: 0}"
                                :zoom="7"
                                :options="{
                                   gestureHandling: 'cooperative',
                                   zoomControl: true,
                                   mapTypeControl: true,
                                   scaleControl: false,
                                   streetViewControl: false,
                                   rotateControl: false,
                                   fullscreenControl: true,
                                   disableDefaultUi: false
                                 }"
                                map-type-id="terrain"
                                ref="googleMapObject"
                            >
                                <GMapCluster
                                    :minimumClusterSize="200"
                                >
                                    <GMapMarker
                                        v-for="(marker, index) in mapMarkers"
                                        :key="index"
                                        :position="marker.location"
                                        :clickable="true"
                                        :draggable="false"
                                        @click="openInfo(marker)"
                                    >
                                        <GMapInfoWindow class="opened-window" :opened="marker.opened">
                                            <img :src="marker.img" :alt="marker.title" />
                                            <h6>{{ marker.name }}</h6>
                                            <table>
                                                <tr class="address" v-if="marker.address"><td><ion-icon :icon="locationOutline"></ion-icon></td><td v-html="marker.address"></td></tr>
                                                <tr v-if="marker.phone"><td><ion-icon :icon="callOutline"></ion-icon></td><td>{{ marker.phone }}</td></tr>
                                            </table>
                                            <p class="ion-text-center">
                                                <ion-button @click="this.$router.push({ path: urlType(marker.type) + marker.slug })" color="dark" shape="round" fill="outline" expand="block">Czytaj więcej</ion-button></p>
                                        </GMapInfoWindow>
                                    </GMapMarker>
                                </GMapCluster>
                            </GMapMap>
                        </ion-col>
                    </ion-row>
                    <template v-for="(marker, index) in mapMarkers" :key="index">
                        <ion-row class="ion-align-items-center container objlist">
                            <ion-col size-lg="2" size="12" class="ion-align-items-end">
                                <div class="objcat">
                                    <div class="iconbox"><ion-icon :icon="locationOutline"></ion-icon></div> <span>{{ marker.category }}</span>
                                </div>
                            </ion-col>
                            <ion-col size-lg="4" size="12" class="objname">
                                    <h5>{{ marker.name }}</h5>
                                    <ion-item @click="scrollToMap();openInfo(marker)" lines="none" class="showonmap">
                                        <ion-label>
                                            Pokaż na mapie
                                        </ion-label>
                                        <ion-icon :icon="searchCircleOutline"></ion-icon>
                                    </ion-item>

                            </ion-col>
                            <ion-col size-lg="4" size="12">
                                <div class="objaddress" v-if="marker.address">
                                    <ion-icon :icon="locationOutline"></ion-icon> <p v-html="marker.address"></p>
                                </div>
                            </ion-col>
                            <ion-col size-lg="2" size="12">
                                <div class="objphone" v-if="marker.phone">
                                    <ion-icon :icon="callOutline"></ion-icon><p>{{ marker.phone }}</p>
                                </div>
                            </ion-col>
                        </ion-row>
                    </template>
                </ion-grid>

            </section>
            <footer-section></footer-section>
        </ion-content>

    </ion-page>
</template>

<style scoped>
ion-row.objlist{
    --ion-grid-column-padding: 10px;
    --ion-grid-column-padding-xs: 10px;
    --ion-grid-column-padding-sm: 10px;
    --ion-grid-column-padding-md: 10px;
    --ion-grid-column-padding-lg: 10px;
    --ion-grid-column-padding-xl: 10px;
    border-bottom:solid 1px #ccc;
}
.objlist ion-item{
    --background:transparent;
}
.objlist ion-col{
    padding-top:30px;
    padding-bottom:30px;
}
.objcat, .objaddress, .objphone{
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
}
.objcat span{
    color:#46a352;
    text-transform: uppercase;
    font-size:0.75em;
    font-weight:600;
    margin-left:10px
}

.iconbox{
    background: var(--ion-color-greengradient);
    text-align: center;
    height:50px;
    width:50px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}
ion-icon{
    font-size:30px;
    color:#46a352;
    margin-right:10px;
}
h5{
    margin:0;
    padding:0;
    font-size:1.35em;
    font-weight: 600;
}
.showonmap{
    margin:0;
    display: inline-block;
    --padding-start: 0px;
    cursor:pointer;
}
.showonmap ion-icon{
    margin-left:5px;
}

.iconbox ion-icon{
    color:#fff;
    font-size: 30px;
    margin-right: 0;
}
div.vue-map-container {
    height: 500px !important;
}
.masked{
    mask-image: url("../../../public/assets/img/maskbigmap.svg");
    mask-repeat: no-repeat;
    mask-size: 100%;
    mask-position: top left;
}
.opened-window img{
    width:150px;
    float:left;
    margin: 0 10px 10px 0;
}
.opened-window table{
    display: block;
    width: 320px;
}
.opened-window td:first-child{
    vertical-align: top;
    padding-right:15px;
}
.opened-window td{
    padding-bottom: 10px;
    letter-spacing: 0.5px;
}
.opened-window ion-icon{
    color:#3e9355;
    font-size:1.2em
}
.opened-window a{
    color:#3e9355;
    text-decoration: none;
}
body.contrast ion-item{
    --background:transparent;
}
body.contrast .opened-window{
    background-color:#1e1e1e;
    padding:10px
}
@media screen and (max-width: 991px) {
    .container .objlist{
        text-align: center;
        margin:30px auto;

    }
    .objaddress, .objphone, .objcat, .objname{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .objcat{
        padding-top:20px;
    }
    .objcat span{
        margin-left:0;
    }
    .objlist ion-col{
        padding:0;
    }
    .objname h5{
        text-align: center;
        margin-top:15px;
    }
    .objname ion-item{
        margin: 0 auto;
    }

}
</style>

<script>

import {IonContent, IonPage, IonGrid, IonRow, IonCol, IonIcon} from '@ionic/vue';
import {defineComponent} from 'vue';
import {listing as listingPlace}  from "../../api/modules/place";
import {listing as listingInfrastructure} from "../../api/modules/infrastructure";
import MapFilterSearch from "./MapFilterSearch";
import {locationOutline, callOutline, searchCircleOutline } from "ionicons/icons";
import FooterSection from "../../sections/footerSection";

export default defineComponent({
    name: 'ObjectMap',
    components: {
        FooterSection,
        IonContent,
        IonPage,
        IonGrid,
        IonRow,
        IonCol,
        IonIcon,
        MapFilterSearch
    },
    setup(){
      return{
          locationOutline, callOutline, searchCircleOutline
      }
    },
    data() {
        this.setTitle('Mapa Aktywnego Pogranicza');
        return {
            params: this.$route.params,
            content: [],
            mapMarkers: [],
            filter: 0,
            community: '',
            search:'',
            total: 0
        }
    },
    methods: {
        resolveMap() {
            let bounds = new window.google.maps.LatLngBounds();
            this.mapMarkers = this.content.sort((a,b)=>{
                return (a.title > b.title) ? 1 : ((a.title < b.title) ? -1 : 0);
            }).filter((item) => {
                let objCategory = true;
                let community = true;
                let search = item.title.toLowerCase().includes(this.search.toLowerCase());
                if(this.filter !== 0 && item.category){
                    objCategory = (item.category.slug === this.filter);
                }
                if(this.community && item.community){
                    community = (Number(item.community.id) === Number(this.community));
                }
                return objCategory && community && search;
                //return Number(this.filter) === 0 || (item.category && (this.filter === item.category.slug) && item.community && (item.community.id === this.setCommunity));
            }).map((item) => {
                if(item.location && item.location.lat && item.location.lng) {
                    const nbLat = Number(item.location.lat);
                    const nbLng = Number(item.location.lng);
                    if(nbLat && nbLng) {
                        const locForApi = new window.google.maps.LatLng(Number(item.location.lat), Number(item.location.lng));
                        bounds.extend(locForApi);
                    }
                }
                return {
                    name: item.title,
                    address: item.address,
                    postal: item.postal,
                    locality: item.locality,
                    phone: item.phone,
                    email: item.email,
                    website: item.website,
                    img: item.main_image.src,
                    slug: item.slug,
                    type: item.item_type,
                    location: {
                        lat: Number(item.location.lat),
                        lng: Number(item.location.lng),
                        communityId: item.community.id,
                        communityTitle: item.community.title
                    },
                    opened: false
                }
            });
            this.$nextTick(() => {
                this.$refs.googleMapObject.fitBounds(bounds);
                this.$refs.googleMapObject.panToBounds(bounds);
            });
        },
        openInfo(marker) {
            this.mapMarkers.map((item) => item.opened = false);
            marker.opened = !marker.opened;
        },
        toggleColor(item){
            return ((item === 0 && this.filter === 0) ? 'greengradient' : ((this.filter === item) ? 'greengradient' : 'light'));
        },
        setFilter(val){
            this.filter = val;
        },
        setCommunity(val){
            this.community = val;
        },
        setSearch(val){
            this.search = val;
        },
        scrollToMap(){
            let map = document.querySelector('#map-header');
            let content = document.querySelector('ion-content#mapContent')
            content.scrollToPoint(0, map.offsetTop, 1000);
        },
        urlType(type){
            switch (type){
                case 'infrastructure':
                    return '/infrastruktura/';
                case 'place':
                    return '/miejsca/';
                default:
                    return '/';
            }
        }
    },
    mounted() {
        let slug = this.params.slug;
        Promise.all([listingPlace(slug), listingInfrastructure(slug)])
            .then((response) => {
                let objects = response.map((val)=>{
                    return val.data.data
                });
                if(response[0].data.status === 'success' && response[1].data.status === 'success'){
                    this.content = [].concat(objects[0], objects[1]);
                    const checkGoogleMaps = () => {
                        if(window && window.google && window.google.maps) {
                            clearInterval(myInterval)
                            this.resolveMap();
                        }
                    }
                    const myInterval = setInterval(checkGoogleMaps, 200);
                }
            });
    },
    watch: {
        filter() {
            this.$nextTick(() => {
                this.resolveMap();
            });
        },
        community() {
            this.$nextTick(() => {
                this.resolveMap();
            });
        },
        search() {
            this.$nextTick(() => {
                this.resolveMap();
            });
        }
    },
});

</script>


