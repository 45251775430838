<template>
    <ion-page>
        <ion-content class="singlenews">
            <ion-grid>
                <ion-row class="container ion-justify-content-around">
                    <ion-col class="ion-text-left ion-margin-vertical" size="12">
                        <div class="photoborder" v-if="content.main_image">
                            <div class="photo"
                                 :style="{'background-image' : 'url(' + $imageUrl(content.main_image,'main_box') + ')'}"
                                 @click="openImg($imageUrl(content.main_image), content.title)">
                            </div>
                        </div>
                        <ion-row class="ion-align-items-center" v-if="content.title">
                            <ion-col size="12" class="newsdet ion-no-margin ion-no-padding">
                                <p class="ion-no-margin">

                                    <ion-icon :icon="timeOutline"></ion-icon>
                                    {{ content.date_simple }}
                                    <span class="cat">{{ content.category.title }}</span>
                                </p>
                            </ion-col>
                            <ion-col size="12">

                            </ion-col>
                        </ion-row>
                        <h1 v-if="content.title">{{ content.title }}</h1>
                        <audio-loader :blocks="content.blocks"></audio-loader>


                        <span class="newscontent" v-html="content['content']"></span>

                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="12">
                        <block-loader :blocks="content.blocks"></block-loader>
                    </ion-col>
                </ion-row>
            </ion-grid>
            <footer-section></footer-section>
        </ion-content>
    </ion-page>
</template>
<style scoped>
.photoborder {
    width: 45%;
    float: left;
    margin: 0 30px 30px 0;
}

.newsdet {
    font-size: 1.5em;
    font-family: "Just Another Hand";
    text-transform: uppercase;
}

.newsdet p {
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    line-height: 29px;
}

.newsdet ion-icon {
    color: #2a8f75;
    font-size: 1em;
    margin-right: 15px;
}

span.cat {
    font-size: 0.6em;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #46a352;
    margin-left: 20px;
    letter-spacing: 2px;
}

@media screen and (max-width: 991px) {
    .photoborder {
        width: 100%;
        float: none;
        margin: 0 0 30px 0;
    }
}
</style>

<script>

import {IonCol, IonContent, IonGrid, IonIcon, IonPage, IonRow} from '@ionic/vue';
import {defineComponent} from 'vue';
import {show} from "@/api/modules/news";
import AudioLoader from "../../views/AudioLoader";
import BlockLoader from "../../views/BlocksLoader";
import {timeOutline} from 'ionicons/icons';
import FooterSection from "../../sections/footerSection";
import ImageMixin from "../../mixins/image"


export default defineComponent({
    name: 'NewsShow',
    components: {
        FooterSection,
        AudioLoader,
        BlockLoader,
        IonContent,
        IonPage,
        IonGrid,
        IonRow,
        IonCol,
        IonIcon
    },
    data() {
        return {
            params: this.$route.params,
            content: {}
        }
    },
    setup() {
        return {
            timeOutline
        }
    },
    mounted() {
        show(this.params.slug).then((response) => {
            if (response.data.status === 'success') {
                this.content = JSON.parse(JSON.stringify(response.data.data)); // this.content = response.data.data || {};
                this.setTitle(this.content.title);
            } else {
                alert('There was a problem with loading your content.')
            }
        });
    },
    mixins: [
        ImageMixin
    ]
});

</script>
