<template>
    <ion-page>
        <ion-content class="singlepage">
            <ion-grid>
                <ion-row>
                    <ion-col class="ion-text-center">
                        <h1 v-if="content.title">{{ content.title }}</h1>
                    </ion-col>
                </ion-row>
                <ion-row class="container ion-justify-content-around">
                    <ion-col class="ion-text-left ion-margin-vertical" size="12">
                        <template v-if="content.main_image">
                            <div class="photoborder" v-if="content.main_image.src">
                                <div class="photo"
                                     :style="{'background-image' : 'url(' + $imageUrl(content.main_image) + ')'}"
                                     @click="openImg($imageUrl(content.main_image), content.title)">
                                </div>
                            </div>
                        </template>

                        <audio-loader :blocks="content.blocks"></audio-loader>
                        <span class="newscontent" v-html="content.description"></span>

                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="12">
                        <block-loader :blocks="content.blocks"></block-loader>
                    </ion-col>
                </ion-row>
            </ion-grid>
            <footer-section></footer-section>
        </ion-content>
    </ion-page>


</template>

<style scoped>
.singlepage ion-grid:first-child {
    min-height: calc(100vh - 500px);
}

.photoborder {
    width: 45%;
    float: left;
    margin: 0 30px 30px 0;
}

@media screen and (max-width: 991px) {
    .photoborder {
        width: 100%;
        float: none;
        margin: 0 0 30px 0;
    }
}
</style>

<script>

import {IonCol, IonContent, IonGrid, IonPage, IonRow} from '@ionic/vue';
import {defineComponent} from 'vue';
import {exampleCallForShow} from "@/api/modules/page";
import AudioLoader from "../../views/AudioLoader";
import BlockLoader from "../../views/BlocksLoader";
import FooterSection from "../../sections/footerSection";
import ImageMixin from "@/mixins/image";

export default defineComponent({
    name: 'PageShow',
    components: {
        FooterSection,
        IonPage,
        AudioLoader,
        BlockLoader,
        IonContent,
        IonGrid, IonRow, IonCol
    },
    data() {
        return {
            params: this.$route.params,
            content: {}
        }
    },
    mounted() {
        let slug = this.params.slug;
        //let slug = (this.params.slug) ? 'przykladowa-strona' : null; // This is just for API testing, please remove it eventually.
        exampleCallForShow(slug).then((response) => {
            if (response.data.status === 'success') {
                this.content = response.data.data || {};
                this.setTitle(this.content.title);
            } else {
                alert('There was a problem with loading your content.')
            }
        });

    },
    mixins: [
        ImageMixin
    ]
});

</script>
