<template>
    <ion-page>

        <ion-header>
            <ion-toolbar>
                <ion-title>{{ this.$options.name }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content>
            <div class="ag-container">

                <b>Parametry otrzymane z routera:</b><br/>
                <template v-if="Object.keys(params).length">
                    <template v-for="(value, key) in params" :key="key">
                        <b>{{ key }}:</b> {{ value }}<br/>
                    </template>
                </template>
                <template v-else>
                    [ Brak ]
                </template>

                <div class="ag-task">
                    Zadanie komponentu: pobranie przez AJAX strony pojedynczej kamery "{{ params.slug }}".
                </div>

                <template v-if="content.title">

                    <template v-if="content.main_image">
                        <img class="ag-img-thumbnail" :src="$imageUrl(content.main_image)" alt="content.title"/>
                    </template>

                    <b>Title:</b> <span v-html="content.title"></span>
                    <br/><br/>
                    <b>Description:</b> <span v-html="content.description"></span>

                    <block-loader :blocks="content.blocks"></block-loader>

                </template>

                <template v-else>
                    Ładowanie....
                </template>

            </div>
        </ion-content>

    </ion-page>
</template>

<script>

import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from '@ionic/vue';
import {defineComponent} from 'vue';
import {exampleCallForShow} from "../../api/modules/news";
import BlockLoader from "../../views/BlocksLoader";

export default defineComponent({
    name: 'CameraShow',
    components: {
        BlockLoader,
        IonContent,
        IonHeader,
        IonPage,
        IonTitle,
        IonToolbar
    },
    data() {
        return {
            params: this.$route.params,
            content: {}
        }
    },
    mounted() {
        //let slug = this.params.slug;
        let slug = (this.params.slug) ? 'przykladowa-strona' : null; // This is just for API testing, please remove it eventually.
        exampleCallForShow(slug).then((response) => {
            if (response.data.status === 'success') {
                this.content = response.data.data || {};
            } else {
                alert('There was a problem with loading your content.')
            }
        });
    }
});

</script>
