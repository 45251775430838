import request from '../../helpers/request'

export function categories() {
    return request({
        url: `/place/categories/`,
        method: 'get',
        params: {}
    })
}

export function listing(slug) {
    return request({
        url: (slug) ? `/place/listing/${slug}` : `/place/listing/`,
        method: 'get',
        params: {}
    })
}

export function show(slug) {
    return request({
        url: `/place/show/${slug}`,
        method: 'get',
        params: {}
    })
}
