<template>
    <div class="photoborder">
        <div class="photo" @click="this.$router.push({'path' : `/aktualnosci/${item.slug}`})" :style="{'background-image' : 'url(' + $imageUrl(item.main_image) + ')'}">
        </div>
    </div>
    <div v-if="item.category.title" class="ion-text-center category ion-margin-top">{{ item.category.title }}</div>
    <h3><a :href="`/aktualnosci/${item.slug}/`">{{ item.title }}</a></h3>
    <div class="ion-text-center pubdate ion-margin-vertical">
        <ion-icon :icon="timeOutline"></ion-icon>
        {{ item['date_simple'] }}
    </div>
    <div class="ion-text-left shortcontent ion-margin-top ion-padding-top">
        {{ this.trimToLength(item['description'], 230) }}
    </div>
</template>

<style scoped>


.category {
    color: #46a352;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9em;
    letter-spacing: 2px;
}

h3 {
    font-size: 1.5em;
}

h3 a {
    font-weight: 600;
    color: #000;
    text-decoration: none;
}

h3 a:hover {
    color: #48a451;
    text-decoration: underline;
}

.pubdate {
    font-family: 'Just Another Hand';
    font-size: 1.15em;
    text-transform: uppercase;
}

.shortcontent {
    color: #6c6c6c;
    font-size: 0.9em;
    line-height: 1.9;
}
body.contrast h3 a, body.contrast .shortcontent{
    color:#fff;
}

</style>

<script>

import {IonIcon} from '@ionic/vue';
import {timeOutline} from 'ionicons/icons';
import {defineComponent} from 'vue';

export default defineComponent({
    components: {
        IonIcon
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    setup() {
        return {
            timeOutline
        }
    },
});

</script>
