import Model from '../model'

class Participant extends Model {

    defaults() {
        return {
            id: null,
            name: '',
            email: '',
            phone: '',
        }
    }

    post() {
        return {
            id: this.id,
            name: this.name,
            email: this.email,
            phone: this.phone,
        }
    }
}

export default Participant
