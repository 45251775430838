import request from '../../helpers/request'

export function create(data) {
    return request({
        url: `/ticket/create/`,
        method: 'post',
        data: data
    })
}

export function show(id, hash) {
    return request({
        url: `/ticket/show/${id}/${hash}`,
        method: 'get',
        params: {}
    })
}

export function message(id, hash, data) {
    return request({
        url: `/ticket/message/${id}/${hash}`,
        method: 'post',
        data: data
    })
}
