import {navigation} from "../api/navigation.js";

const siteStructure = new Promise((resolve, reject) => {
    navigation().then((response) => {
        if (response.data.status === 'success') {
            resolve(response.data.data.navigation)
        } else {
            reject([])
        }
    });
});

const menuSorter = (menu) => {
    menu.sort((a, b) => a.position - b.position);
    menu.map((item) => {
       if(item.children && item.children.length) {
           item.children = menuSorter(item.children);
       }
    });
    return menu;
}

const menuResolver = (menu) => {
    return siteStructure.then((structure) => {
        return menuSorter(structure[menu] || []);
    });
}

export default menuResolver;
