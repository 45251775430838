<template>
    <ion-page>

        <ion-header>
            <ion-toolbar>
                <ion-title>{{ this.$options.name }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content>
            <div class="ag-container">

                <b>Parametry otrzymane z routera:</b><br/>
                <template v-if="Object.keys(params).length">
                    <template v-for="(value, key) in params" :key="key">
                        <b>{{ key }}:</b> {{ value }}<br/>
                    </template>
                </template>
                <template v-else>
                    [ Brak ]
                </template>

                <div class="ag-task">
                    Zadanie komponentu: pobranie przez AJAX listy elementów przypisanych do tagu "{{ params.slug }}".
                </div>

                <template v-if="content.length">

                    <template v-for="news in content" :key="news.id">
                        <div style="clear: both;"></div>
                        <img class="ag-img-thumbnail" :src="$imageUrl(news.main_image)" alt="content.title"/>
                        <h5 v-html="news.title"></h5>
                        <span v-html="news.description"></span>
                    </template>

                </template>

                <template v-else>
                    Ładowanie....
                </template>

            </div>
        </ion-content>

    </ion-page>
</template>

<script>

import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from '@ionic/vue';
import {defineComponent} from 'vue';
import {exampleCallForListing} from "../../api/modules/news";

export default defineComponent({
    name: 'TagItemListing',
    components: {
        IonContent,
        IonHeader,
        IonPage,
        IonTitle,
        IonToolbar
    },
    data() {
        return {
            params: this.$route.params,
            content: {}
        }
    },
    mounted() {
        //let slug = this.params.slug;
        let slug = (this.params.slug) ? 'przykladowa-strona' : null; // This is just for API testing, please remove it eventually.
        exampleCallForListing(slug).then((response) => {
            if (response.data.status === 'success') {
                this.content = response.data.data.data || {};
            } else {
                alert('There was a problem with loading your content.')
            }
        });
    }
});

</script>
