<template>
    <ion-row>
        <ion-col size="12" class="ion-no-margin ion-no-padding ion-margin-bottom">
            <div class="stars" @click="toggleRateBox()">
                <template v-for="(item,i) in stars" :key="i">
                    <ion-icon :name="item"></ion-icon>
                </template>
                <span class="scorescnt">({{ comments.scores_cnt }}) Oceń te miejsce &raquo;</span>
            </div>
        </ion-col>
    </ion-row>

    <ion-row class="ratebox ion-margin-bottom ion-text-center ion-justify-content-center ion-align-items-center">
        <template v-if="!voted">
            <ion-col size="12" class="voting">
                <h3 class="ion-text-center">Twoja ocena</h3>
                <div class="ion-margin-bottom">
                    <template v-for="i in 5" :key="i">
                        <ion-icon @click="myScore = i" :icon="(myScore < i) ? starOutline : star"></ion-icon>
                    </template>
                </div>
                <ion-button class="ion-margin-bottom" :disabled="loading || !comments.item_id" color="greengradient" shape="round" @click="vote">
                    Oceń na {{ myScore }}
                    <ion-icon :icon="star" color="light"></ion-icon>
                </ion-button>
            </ion-col>
        </template>
        <template v-else-if="voted === true">
            <ion-col size="12">
                <ion-col size="12" class="message"><ion-item lines="none">Dziękujemy za pozostawienie oceny <ion-icon :icon="heartOutline"></ion-icon></ion-item></ion-col>
            </ion-col>
        </template>
        <template v-else>
            <ion-col size="12" class="message"><ion-item lines="none">Już oceniałeś te miejsce. Dziękujemy <ion-icon :icon="heartOutline"></ion-icon></ion-item></ion-col>
        </template>
    </ion-row>

</template>

<style scoped>
ion-icon {
    color: #208a6f;
}

ion-button ion-icon {
    font-size: 1em !important;
    margin-left: 5px
}

.scorescnt {
    font-size: 0.9em;
}

.ratebox {
    background-color: #46a35221;
    height: 0;
    opacity: 0;
    transition: all 0.5s ease
}

.ratebox h3 {
    font-family: "Just Another Hand";
    font-weight: 400;
    font-size: 3em;
}

.ratebox ion-icon {
    font-size: 2em;
    cursor: pointer;
}
.message ion-item{
    --background: transparent;
    display: inline-block;
}
.message ion-icon{
    margin-left:5px;
}
</style>

<script>

import {defineComponent} from 'vue';
import {getStoreInstance} from '../helpers/storage';
import {submit} from "../api/comment";
import {starOutline, starHalfOutline, star, heartOutline} from "ionicons/icons";

export default defineComponent({
    name: 'CommentBox',
    setup() {
        return {
            starOutline, starHalfOutline, star,heartOutline
        }
    },
    components: {},
    model: {
        prop: 'comments',
        event: 'update'
    },
    props: {
        comments: {
            type: Object,
            required: true,
            default: () => {
            }
        }
    },
    data() {
        return {
            myScore: '5', //This is intentionally stored as string, due to strict comparing in <select> value.
            loading: false,
            store: null,
            voted: true,
            stars: [],
            rateBoxOpen: false
        }
    },
    computed: {
        storageString() {
            return 'vote' + '-' + this.comments.item_class + '-' + this.comments.item_id;
        }
    },
    methods: {
        vote() {
            this.loading = true;
            submit({
                score: Number(this.myScore) || 0,
                item_class: this.comments.item_class,
                item_id: this.comments.item_id,
            }).then((response) => {
                if (response.data.status === 'success') {
                    this.$emit('update:comments', response.data.data.comments);
                    this.store.setItem(this.storageString, this.myScore);
                    this.voted = true;
                } else {
                    alert('There was a problem with loading your content.')
                }
            });
        },
        starRating(score) {
            this.stars = [];
            let full = Math.floor(score);
            let empty = Math.floor(5 - score);
            for (let i = 0; i < full; i++) {
                this.stars.push('star')
            }
            if (score > full && score < full + 1) {
                this.stars.push('star-half-outline');
            }
            for (let i = 0; i < empty; i++) {
                this.stars.push('star-outline');
            }
        },
        toggleRateBox() {
            this.rateBoxOpen = !this.rateBoxOpen;
            let box = document.querySelector('.ratebox');
            if (this.rateBoxOpen === false) {
                box.style.height = "0px";
                box.style.opacity = "0";
            } else {
                box.style.height = "auto";
                box.style.opacity = "1";
            }
        }
    },
    mounted() {
        this.store = getStoreInstance();
        this.store.getItem(this.storageString).then(response => {
            this.voted = Number(response);
        });
        this.starRating(this.comments.scores_avg);
    },
    watch:{
        comments: function(){
            this.starRating(this.comments.scores_avg);
        }
    }
});

</script>
