<template>
    <swiper
        :spaceBetween="0"
        :centeredSlides="true"
        :pagination='{
  "clickable": true
}'
        :navigation="true"
        class="vc_slider"
    >
        <swiper-slide style="background:url('/assets/slider/1.jpg');background-size: cover;"><div class="slide1"><span>Szlakiem przyrody</span></div><div style="position:absolute;bottom:10%"><ion-button color="light" fill="outline" shape="round" size="default" @click="this.$router.push({path: '/szlakiem-przyrody'})">ZOBACZ WIĘCEJ</ion-button></div></swiper-slide>
        <swiper-slide style="background:url('/assets/slider/1.jpg');background-size: cover;"><div class="slide1"><span>Szlakiem przyrody</span></div><div style="position:absolute;bottom:10%"><ion-button color="light" fill="outline" shape="round" size="default" @click="this.$router.push({path: '/szlakiem-przyrody'})">ZOBACZ WIĘCEJ</ion-button></div></swiper-slide>
        <swiper-slide style="background:url('/assets/slider/1.jpg');background-size: cover;"><div class="slide1"><span>Szlakiem przyrody</span></div><div style="position:absolute;bottom:10%"><ion-button color="light" fill="outline" shape="round" size="default" @click="this.$router.push({path: '/szlakiem-przyrody'})">ZOBACZ WIĘCEJ</ion-button></div></swiper-slide>
        <swiper-slide style="background:url('/assets/slider/1.jpg');background-size: cover;"><div class="slide1"><span>Szlakiem przyrody</span></div><div style="position:absolute;bottom:10%"><ion-button color="light" fill="outline" shape="round" size="default" @click="this.$router.push({path: '/szlakiem-przyrody'})">ZOBACZ WIĘCEJ</ion-button></div></swiper-slide>

    </swiper>
</template>`



<style scoped>


.slide1{
    max-height: 515px;
    width:100%;
    height:80vh;
    background:transparent url('../../../public/assets/slider/aktywnepogranicze.png') no-repeat;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 90px;
    color:#fff;
    position: relative;
}
.slide1 span{
    position: absolute;
    top:50%;
}
.swiper {
    width: 100%;
    height: 85vh;
}

.swiper .swiper-button-prev{
    display: none;
}
.swiper .swiper-pagination{
    text-align: right !important;
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@media screen and (max-width: 991px) {
    .vc_slider .slide1{
        background-size: contain;
        font-size: 7.5vw;
    }
    .vc_slider.swiper {
        width: 100%;
        height: 100vh;
    }
}
@media screen and (max-width: 991px) and (orientation: landscape){
    .vc_slider .slide1{
        font-size: 12vh;
    }
}
body.contrast div[class*='slide']{
    filter: drop-shadow(2px 4px 6px black);
}
</style>

<script>

import { IonButton } from '@ionic/vue';
import {defineComponent} from 'vue';

import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css';

import "swiper/css/pagination"
import "swiper/css/navigation"

import SwiperCore, {
    Autoplay,Pagination,Navigation
} from 'swiper';


SwiperCore.use([Autoplay,Pagination,Navigation]);

export default defineComponent({
    components: {
        IonButton,
        Swiper,
        SwiperSlide,
    },
});

</script>
