import {modalController} from "@ionic/vue";
import imageModal from "../modules/Modals/imageModal";

export default {
    methods:{
        async openImg(img, alt) {
            //console.log(img);
            const modal = await modalController
                .create({
                    component: imageModal,
                    cssClass: 'modal-image',
                    swipeToClose: true,
                    componentProps: {
                        title: alt,
                        imgSrc: img
                    },
                })
            return modal.present();
        }
    }
}
