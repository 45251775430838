<template>
    <ion-grid class="mapscreen-section ion-no-padding ion-margin-vertical">
        <ion-row class="ion-padding-vertical ion-margin-vertical">
            <ion-col class="ion-text-center ion-padding-vertical ion-margin-vertical">
                <h1 class="container">Atrakcje turystyczne Pogranicza</h1>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <ion-img src="/assets/img/mapscreen.png" @click="this.$router.push({'path':'/mapa'})"></ion-img>
            </ion-col>
        </ion-row>
    </ion-grid>
</template>

<style scoped>
ion-img{
    cursor: pointer;
}
@media screen and (max-width: 991px){
    ion-img{
        height:50vh;
        object-fit: cover;
        cursor: pointer;
    }

}
</style>

<script>
import { IonGrid, IonRow, IonCol, IonImg } from '@ionic/vue';

import {defineComponent} from 'vue';


export default defineComponent({
    components: {
        IonGrid,
        IonRow,
        IonCol,
        IonImg
    },
});
</script>
