<template>

    <ion-grid v-if="related.length" class="related-section ion-padding-bottom">
        <ion-row>
            <ion-col class="ion-text-center ion-margin-bottom ion-padding-bottom">
                <h1 class="container">Zobacz także</h1>
            </ion-col>
        </ion-row>
        <ion-row class="ion-padding-bottom ion-justify-content-between container ion-margin-top ion-padding-top">
            <ion-col v-for="item in related" :key="item.id" size="12" size-lg="3.5" style="position:relative;">
                <div class="photoborder" v-if="item.main_image">
                    <div @click="this.$router.push({'path' : `${url(item.item_type) + item.slug}/`})" class="photo" :style="{'background-image' : 'url(' + $imageUrl(item.main_image) + ')'}"></div>
                </div>
                <h3><a :href="`${url(item.item_type) + item.slug}/`">{{ item.title }}</a></h3>
            </ion-col>
        </ion-row>
    </ion-grid>


</template>

<!--
strony - bez katalogu - page
aktualnosci - /aktualnosci/ -
miejsca - /miejsca/ - attraction
infrastruktura - / - facility

-->

<style scoped>
.related-section{
    background-color:#fff;
    background-image: url("../../../public/assets/img/paperbg.jpg");
    min-height: 819px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 50% 0%;
    padding:80px 0;
}
.photo{
    width:100%;
    padding-top: 90%;
    background-size: cover;
    border:solid 23px #fff;
}
.photoborder{
    border:solid 1px #e6e6e6;
    position: relative;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}
ion-col:nth-child(1) .photoborder{
    transform: rotate(-10deg);
    transition: all 0.2s ease;
}
ion-col:nth-child(1) .photoborder:hover, ion-col:nth-child(3) .photoborder:hover{
    transform: rotate(0deg);
}
ion-col:nth-child(3) .photoborder{
    transform: rotate(10deg);
    transition: all 0.2s ease;
}
.photoborder:nth-child(3){
    transform: rotate(10deg);
}
.photoborder::before{
    background-image: url("../../../public/assets/img/sticker.svg");
    position: absolute;
    content: ' ';
    width: 125px;
    height: 43px;
    background-repeat: no-repeat;
    top: -10px;
    transform: rotate(145deg);
    left: -41px;
}
.photoborder::after{
    background-image: url("../../../public/assets/img/sticker.svg");
    position: absolute;
    content: ' ';
    width: 125px;
    height: 43px;
    background-repeat: no-repeat;
    transform: rotate(145deg);
    bottom: -10px;

    right: -41px;
}
h3{
    margin-top:70px;
    font-size: 2.9em;
    font-family: "Just Another Hand";
    text-align: center;
    font-weight: 400;
    letter-spacing: 2px;
}
h3 a{
    font-weight: 600;
    color:#000;
    text-decoration: none;
}
h3 a:hover{
    text-decoration: underline;
}
body.contrast h1, body.contrast h3 a{
    color:#fff;
}
body.contrast .related-section{
    background-image: none;
    background-color: #000;
}
@media screen and (max-width: 991px){
    .related-section{
        background-image: url("../../../public/assets/img/paperbg.jpg"), url("../../../public/assets/img/paperbg.jpg");
        background-size: 1035px auto, 1035px auto;
        background-repeat: no-repeat, no-repeat;
        background-position: center top, center bottom;
    }
}
</style>

<script>

import { IonGrid, IonRow, IonCol  } from '@ionic/vue';
import {defineComponent} from 'vue';

import {listing as relatedListing} from "../../api/modules/home";

export default defineComponent({
    components: {
        IonGrid,
        IonRow,
        IonCol,
    },
    data() {
        return {
            params: this.$route.params,
            related: []
        }
    },
    mounted() {
        /* realted_objects - Zobacz także */
        relatedListing('related_objects', 3).then((response) => {
            if (response.data.status === 'success') {
                this.related = response.data.data || [];
            } else {
                alert('There was a problem with loading your content.')
            }
        });

    },
    methods:{
        url(i){
            switch(i){
                case "place":
                    return '/miejsca/';
                case "facility":
                    return '/infrastruktura/';
                case "news":
                    return '/aktualnosci/';
                default:
                    return '/';
            }
        }
    }
});

</script>
