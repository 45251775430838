<template>
    <ion-page>
        <ion-content class="chat">
            <ion-grid class="container" v-if="ticket.id">
                <h1 class="ion-text-center">Zgłoszenie o numerze #{{ ticket.id }}</h1>
                <h5 class="ion-text-center">Temat: {{ ticket.title }}</h5>

                Imię i nazwisko: {{ ticket.name }}<br />
                Adres E-Mail: {{ ticket.email }}<br />
                Telefon: {{ ticket.phone }}<br />

                <h3 class="ion-text-center">Historia Wiadomości:</h3>

                <template v-for="message in ticket.messages" :key="message.id">
                    <ion-row class="ion-justify-content-start">
                        <ion-col v-if="message.user_id" size="9" class="ion-padding admin-msg ion-margin-bottom">
                            <h7>Aktywne pogranicze ({{ message.user.name }})</h7>
                            <p class="message">{{ message.content }}</p>
                            <p class="date">{{ message.time_simple }}</p>
                        </ion-col>
                    </ion-row>
                    <ion-row class="ion-justify-content-end">
                        <ion-col size="9" v-if="!message.user_id" class="ion-padding user-msg ion-margin-bottom">
                            <h7>Od Użytkownika ({{ ticket.name }})</h7>
                            <p class="message">{{ message.content }}</p>
                            <p class="date">{{ message.time_simple }}</p>
                        </ion-col>
                    </ion-row>

                </template>

                <ion-row>
                    <ion-col size="12">
                        <h3>Nowa Wiadomość:</h3>
                        <ion-textarea auto-grow="true" rows="2" type="text" :value="message.content" @input="message.content = $event.target.value"></ion-textarea>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="12">
                        <ion-button :disabled="(message.content.length > 0) ? disabled : ''" type="submit" color="greengradient" size="large" expand="block" @click="submitMessage">Wyślij Wiadomość</ion-button>
                    </ion-col>
                </ion-row>




            </ion-grid>
            <footer-section></footer-section>
        </ion-content>

    </ion-page>
</template>

<script>

import {IonContent, IonPage } from '@ionic/vue';
import {defineComponent} from 'vue';
import {show, message} from "../../api/modules/ticket";
import Ticket from "../../models/modules/message";
import Message from "../../models/modules/message";
import FooterSection from "../../sections/footerSection";

export default defineComponent({
    name: 'TicketShow',
    components: {
        FooterSection,
        IonContent,
        IonPage,
    },
    data() {
        return {
            params: this.$route.params,
            ticket: new Ticket,
            message: new Message,
        }
    },
    methods: {
        submitMessage() {
            message(
                this.params.id,
                this.params.hash,
                this.message.getData()
            ).then((response) => {
                if (response.data.status === 'success') {
                    this.ticket.setData(response.data.data || {});
                    this.message.resetData();
                } else {
                    alert('There was a problem with loading your content.')
                }
            });
        }
    },
    mounted() {
        show(
            this.params.id,
            this.params.hash
        ).then((response) => {
            if (response.data.status === 'success') {
                this.ticket.setData(response.data.data || {});
            } else {
                alert('There was a problem with loading your content.')
            }
        });
    }
});

</script>

<style scoped>
ion-grid{
    background-image: url("../../../public/assets/img/placebg.png");
    background-position: center -104px;
    background-size: 100% auto;
    background-repeat: repeat-y;
    color: #6c6c6c;
    min-height: calc(100vh - 500px);
}
h7{
    font-weight: 600;
}
.date{
    font-size:0.7em;
    position: absolute;
    bottom:-5px;
    right: 10px;

}
ion-col{
    border-radius: 15px;
    position: relative;
}
.admin-msg{
    background-color:#ebebeb;
}
.user-msg{
    background-color: #ddf7c9;
}
ion-textarea{
    --background: #f6f6f6;
    border: solid 1px #e3e3e3;
}
</style>
