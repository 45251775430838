import {PushNotifications} from '@capacitor/push-notifications';
import {Capacitor} from "@capacitor/core";
import { FCM } from "@capacitor-community/fcm";


const initPush= async ()=> {

    await PushNotifications.requestPermissions();
    await PushNotifications.register();


// Unsubscribe from a specific topic
    /*
    FCM.unsubscribeFrom({ topic: "test" })
        .then(() => alert(`unsubscribed from topic`))
        .catch((err) => console.log(err));
    */
// Get FCM token instead the APN one returned by Capacitor
    FCM.getToken()
        .then((r) => console.log(`Token ${r.token}`))
        .catch((err) => console.log(err));


// now you can subscribe to a specific topic
    FCM.subscribeTo({ topic: "all" })
        .then((r) => console.log(`subscribed to topic ${r.message}`))
        .catch((err) => console.log(err));
// Remove FCM instance
/*    FCM.deleteInstance()
        .then(() => alert(`Token deleted`))
        .catch((err) => console.log(err));
*/
// Enable the auto initialization of the library
    FCM.setAutoInit({ enabled: true }).then(() => console.log(`Auto init enabled`));

// Check the auto initialization status
    FCM.isAutoInitEnabled().then((r) => {
        console.log("Auto init is " + (r.enabled ? "enabled" : "disabled"));
    });

/*    PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register().then(r => {
                console.log(r);
                //console.log('Push allowed');
            });
        } else {
            console.log('Push denied');
        }
    });
    FCM.setAutoInit({ enabled: true }).then(() => console.log(`Auto init enabled`));
// On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
        (token) => {
            //alert('Push registration success, token: ' + token.value);
            console.log('token: ' + token.value);
        }
    );
// Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
        (error) => {
            alert('Error on registration: ' + JSON.stringify(error));
        }
    );
// Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
        (notification) => {
            //alert('Push received: ' + JSON.stringify(notification));
        }
    );
// Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
        (notification) => {
            //alert('Push action performed: ' + JSON.stringify(notification));
        }
    );

    FCM.getToken()
        .then((r) => {
            console.log(r.token)
        })
        .catch((err) => console.log(err));
    FCM.subscribeTo({ topic: "all" })
        .then((r) => console.log(`subscribed to topic `,r.message))
        .catch((err) => console.log(err));

*/
}

// Enable the auto initialization of the library

const initPushWhenNative=()=>{
    if(Capacitor.isNativePlatform()){
        return initPush()
    }
}

export {initPushWhenNative}
