<template>
    <ion-page>

        <ion-header>
            <ion-toolbar>
                <ion-title>{{ this.$options.name }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content>
            <div class="ag-container">

                <b>Parametry otrzymane z routera:</b><br />
                <template v-if="Object.keys(params).length">
                    <template v-for="(value, key) in params" :key="key">
                        <b>{{ key }}:</b> {{ value }}<br />
                    </template>
                </template>
                <template v-else>
                    [ Brak ]
                </template>

                <div class="ag-task">
                    Zadanie komponentu: wyświetlenie strony błędu 401.
                </div>

            </div>
        </ion-content>

    </ion-page>
</template>

<script>

import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from '@ionic/vue';
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'Error401',
    components: {
        IonContent,
        IonHeader,
        IonPage,
        IonTitle,
        IonToolbar
    },
    data() {
        return {
            params: this.$route.params,
            content: {}
        }
    }
});

</script>
