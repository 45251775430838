<template>
    <ion-page>
        <ion-content>
            <section class="objects">
                <ion-grid>
                    <ion-row>
                        <ion-col size="12">
                            <h1 class="ion-text-center">Infrastruktura na mapie<br>{{filter}}</h1>
                        </ion-col>
                    </ion-row>
                </ion-grid>

                <select v-model="filter" v-if="!params.slug">
                    <option value="0">Wszystkie</option>
                    <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.title }}</option>
                </select>



                <GMapMap
                    :center="{lat: 0, lng: 0}"
                    :zoom="7"
                    map-type-id="terrain"
                    style="width: 500px; height: 400px;"
                    ref="googleMapObject"
                >
                    <GMapCluster
                        :minimumClusterSize="200"
                    >
                        <GMapMarker
                            v-for="(marker, index) in mapMarkers"
                            :key="index"
                            :position="marker.location"
                            :clickable="true"
                            :draggable="false"
                            @click="openInfo(marker)"
                        >
                            <GMapInfoWindow :opened="marker.opened">
                                <b>{{ marker.name }}</b><br />
                                {{ marker.address }}
                            </GMapInfoWindow>
                        </GMapMarker>
                    </GMapCluster>
                </GMapMap>


                <ul class="objectList">
                    <li v-for="(marker, index) in mapMarkers" :key="index">
                        <span @click="openInfo(marker)" :style="{ color: (marker.opened) ? 'blue' : 'black' }"><b>{{ marker.name }}</b></span><br />
                        {{ marker.address }}
                    </li>
                </ul>

            </section>
        </ion-content>

    </ion-page>
</template>

<script>

import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from '@ionic/vue';
import {defineComponent} from 'vue';
import {categories, listing} from "../../api/modules/place";

export default defineComponent({
    name: 'ObjectMap',
    components: {
        IonContent,
        IonPage,
    },
    data() {
        return {
            params: this.$route.params,
            categories: [],
            content: [],
            mapMarkers: [],
            filter: 0
        }
    },
    methods: {
        resolveMap() {
            let bounds = new window.google.maps.LatLngBounds();
            this.mapMarkers = this.content.filter((item) => {
                return Number(this.filter) === 0 || (item.category && Number(this.filter) === item.category.id);
            }).map((item) => {
                if(item.location && item.location.lat && item.location.lng) {
                    const nbLat = Number(item.location.lat);
                    const nbLng = Number(item.location.lng);
                    if(nbLat && nbLng) {
                        const locForApi = new window.google.maps.LatLng(Number(item.location.lat), Number(item.location.lng));
                        bounds.extend(locForApi);
                    }
                }
                return {
                    name: item.title,
                    address: item.location.address,
                    location: {
                        lat: Number(item.location.lat),
                        lng: Number(item.location.lng),
                    },
                    opened: false
                }
            });
            this.$nextTick(() => {
                this.$refs.googleMapObject.fitBounds(bounds);
                this.$refs.googleMapObject.panToBounds(bounds);
            });
        },
        openInfo(marker) {
            this.mapMarkers.map((item) => item.opened = false);
            marker.opened = !marker.opened;
        }
    },
    mounted() {
        let slug = this.params.slug;
        categories(slug).then((response) => {
            if (response.data.status === 'success') {
                this.categories = response.data.data.categories || [];
            } else {
                alert('There was a problem with loading your content.')
            }
        });
        listing(slug).then((response) => {
            if (response.data.status === 'success') {
                this.content = response.data.data || [];
                this.$nextTick(() => {
                    this.resolveMap();
                });
            } else {
                alert('There was a problem with loading your content.')
            }
        });
    },
    watch: {
        filter(value) {
            this.$nextTick(() => {
                this.resolveMap();
            });
        }
    },
});

</script>

<style scoped>

div.vue-map-container {
    width: 1000px !important;
    height: 500px !important;
}

ul.objectList li {
    margin-bottom: 10px;
    cursor: pointer;
}

ul.objectList li b {
    border-bottom: 1px solid #999999;
}

ul.objectList li:hover b {
    border-bottom: 1px solid #FF0000;
}

</style>
