<template>
    <ion-page>
        <ion-content v-on:scroll.passive='handleScroll'>
            <slider-home></slider-home>
            <latest-news></latest-news>
            <feature-listing></feature-listing>
            <div class="bg">
                <infrastructure></infrastructure>
                <map-screen></map-screen>
            </div>
            <mobile-app></mobile-app>
            <related-objects></related-objects>
            <footer-section></footer-section>



        </ion-content>
    </ion-page>
</template>

<style scoped>
ion-content{
    --padding-top: 0px;
}
.bg{
    background: #fff url("../../../public/assets/img/infrastructurebg.jpg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: 50% 20%;
}
body.contrast .bg{
    background:#000;
}
</style>


<script>

import SliderHome from '../../sections/Home/slider';
import LatestNews from '../../sections/Home/latestNews';
import FeatureListing from '../../sections/Home/featureListing';
import Infrastructure from '../../sections/Home/infrastructure'

import { IonPage, IonContent } from '@ionic/vue';
import {defineComponent} from 'vue';
import MapScreen from "../../sections/Home/mapScreen";
import MobileApp from "../../sections/Home/mobileApp";
import RelatedObjects from "../../sections/Home/relatedObjects";
import FooterSection from "../../sections/footerSection";

export default defineComponent({
    name: 'HomeShow',
    ionViewDidEnter() {
        document.querySelector('body').classList.add('homepage');
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount(){
        document.querySelector('body').classList.remove('homepage');
    },
    components: {
        FooterSection,
        RelatedObjects,
        MobileApp,
        MapScreen,
        FeatureListing,
        LatestNews,
        SliderHome,
        Infrastructure,
        IonContent,
        IonPage
    },

    data() {
        this.setTitle();
        return {
            params: this.$route.params,
            feature: []
        }
    },
    methods:{
        handleScroll(event){
            //console.log('asd', event);
        }
    },

});

</script>
