<template>
    <ion-page>
        <ion-content>

            <ion-grid class="container addticket">
                <ion-row class="ion-justify-content-around">
                    <ion-col size-lg="6" size="12">
                        <h1 class="ion-text-center">Napisz do nas</h1>
                        <ion-item>
                            <ion-label position="floating">Imię i nazwisko</ion-label>
                            <ion-input type="text" :value="ticket.name" @input="ticket.name = $event.target.value" autofocus="true"></ion-input>
                        </ion-item>
                        <ion-item>
                            <ion-label position="floating">Adres e-mail</ion-label>
                            <ion-input type="email" :value="ticket.email" @input="ticket.email = $event.target.value" autocomplete="email"></ion-input>
                        </ion-item>
                        <ion-item>
                            <ion-label position="floating">Numer telefonu</ion-label>
                            <ion-input type="number" :value="ticket.phone" @input="ticket.phone = $event.target.value" autocomplete="tel"></ion-input>
                        </ion-item>
                        <ion-item>
                            <ion-label position="floating">Temat wiadomości</ion-label>
                            <ion-input type="text" :value="ticket.title" @input="ticket.title = $event.target.value"></ion-input>
                        </ion-item>
                        <ion-item>
                            <ion-label position="floating">Treść wiadomości</ion-label>
                            <ion-textarea :value="ticket.content" @input="ticket.content = $event.target.value" auto-grow="true"></ion-textarea>
                        </ion-item>


                        <ion-button type="submit" color="greengradient" expand="block" @click="submitTicket" :disabled="ticket.name.length===0 || ticket.email.length === 0 || ticket.phone.length === 0 || ticket.title.length === 0 || ticket.content.length === 0">Zapisz Zgłoszenie</ion-button>

                    </ion-col>
                </ion-row>
            </ion-grid>
            <footer-section></footer-section>

        </ion-content>

    </ion-page>
</template>

<style scoped>
.addticket{
    min-height: calc(100vh - 500px);
}
</style>

<script>

import {IonContent, IonPage, IonGrid, IonRow, IonCol, IonItem, IonLabel, IonInput, IonButton } from '@ionic/vue';
import {defineComponent} from 'vue';
import {create} from "../../api/modules/ticket";
import Ticket from "../../models/modules/ticket";
import FooterSection from "../../sections/footerSection";

export default defineComponent({
    name: 'TicketCreate',
    components: {
        FooterSection,
        IonContent,
        IonPage,
        IonGrid,
        IonRow,
        IonCol,
        IonItem,
        IonLabel,
        IonInput,
        IonButton
    },
    data() {
        return {
            params: this.$route.params,
            ticket: new Ticket,
        }
    },
    methods: {
        submitTicket() {
            create(this.ticket.getData()).then((response) => {
                if (response.data.status === 'success') {
                    this.ticket.setData(response.data.data || {});
                    this.$router.push({
                        name: 'ticket.show',
                        params: {
                            id: this.ticket.id,
                            hash: this.ticket.hash
                        }
                    })
                } else {
                    alert('There was a problem with loading your content.')
                }
            });
        }
    }
});

</script>

<style>
div.ag-container input, div.ag-container textarea {
    margin-bottom: 20px !important;
    border: 1px solid #666 !important;
    background-color: #EEEEEE !important;
}
</style>
