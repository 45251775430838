<template>
    <ion-menu class="vc_menumob" side="end" content-id="main-content">
        <ion-content color="greengradient">
            <img @click="this.$router.push({name: 'home.show'}); closeMenu()" src="/assets/img/logo_black.svg" class="logo" />

            <ion-list class="ion-no-margin ion-no-padding">
                <template v-for="item in mainMenu" :key="item.name">
                    <ion-item color="greengradient" :title="item.name">
                        <ion-icon v-if="item.children.length > 0" slot="end" :icon="menuStateIcon(item.position)"
                                  :class="'ex-'+item.position" @click="toggleShow(item.position)"></ion-icon>
                        <ion-label @click="closeMenu(); this.$router.push({'path' : item.path })">{{ item.name }}</ion-label>
                    </ion-item>
                    <template v-if="item.children.length > 0">
                        <ul class="submenu" :class="'sub-'+item.position">
                            <ion-item color="greengradient" v-for="child in item.children" :key="child.name" :router-link="child.path"
                                      :title="child.name">
                                <ion-label @click="closeMenu()">{{ child.name }}</ion-label>
                            </ion-item>
                        </ul>
                    </template>
                </template>
            </ion-list>
        </ion-content>
    </ion-menu>
    <ion-toolbar class="vc_menumob ion-hide-lg-up front">
        <img @click="this.$router.push({name: 'home.show'})" src="/assets/img/logo.svg" alt="logo aktywne pogranicze" />
        <ion-buttons slot="end" >
            <ion-menu-button></ion-menu-button>
        </ion-buttons>
    </ion-toolbar>


</template>

<style scoped>
.submenu {
    display: none;
}
</style>

<script>

import {
    //IonHeader,
    //IonTitle,
    IonToolbar,
    IonItem,
    IonList,
    IonMenu,
    menuController,
    IonButtons,
    IonMenuButton,
    IonIcon,
    IonLabel,
    IonContent
} from '@ionic/vue';
import {addOutline, removeOutline, closeOutline} from 'ionicons/icons';

import {defineComponent} from 'vue';
import menuResolver from "../helpers/structure";


export default defineComponent({
    components: {
       // IonHeader,
        //IonTitle,
        IonToolbar,
        IonItem,
        IonList,
        IonMenu,
        IonButtons,
        IonMenuButton,
        IonIcon,
        IonLabel,
        IonContent
    },
    props: ['topMenuLinks'],
    methods: {
        closeMenu() {
            menuController.close();
        },
        toggleShow(x) {
            let subcontainer = document.querySelector('.sub-' + x);
            if (subcontainer.style.display === 'block') {
                subcontainer.style.display = 'none';
                this.isExpandedSub[x] = addOutline;
            } else {
                subcontainer.style.display = 'block';
                this.isExpandedSub[x] = removeOutline;
            }
        },
        menuStateIcon(x){
            return this.isExpandedSub[x] ? this.isExpandedSub[x] : addOutline;
        }
        /*toggleIco(x){
            console.log('toggleIco');
            console.log(x);
            let subcontainer = document.querySelector('.sub-' + x)
            console.log('subcontainer: ', subcontainer)
            if (subcontainer) {
                if (subcontainer.style.display === 'block') {
                    console.log('removeOutline')
                    return removeOutline
                } else {
                    console.log('addOutline')
                    return addOutline
                }
            } else {
                console.log('DEFAULT closeOutline')
                return addOutline
            }
        }*/
    },
    setup() {
        return {
            addOutline, removeOutline, closeOutline
        }
    },
    data() {
        return {
            mainMenu: [],
            isExpandedSub: {

            },
        }
    },
    mounted: function () {
        menuResolver('main-menu').then((menu) => {
            this.mainMenu = menu;
        });
    },
});
</script>
<style>
ion-menu ion-content{
    --padding-top: 0px;
}
ion-menu-button{
    --color: #3fa039;
}
.vc_menumob.show-menu {
    height: 100vh;
}
ul.submenu{
    margin:0;
    padding:0;
}
ul.submenu ion-label{
    padding-left:20px;
}

ion-toolbar.front {
    --background: url("../../public/assets/img/menubg.png") repeat-x center / auto 100%;
    --ion-color-base: transparent !important;
    text-align: left;
}
ion-toolbar.front > img{
    height:34px;
    margin-left: 20px;
}

</style>
