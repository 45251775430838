<template>
    <ion-grid v-if="news.length" class="news-section">
        <ion-row>
            <ion-col class="ion-text-center">
                <h1 class="container">Aktualności <ion-button @click="this.$router.push({name: 'news.listing'})" color="greengradient" class="showmore ion-hide-lg-down" shape="round">ZOBACZ WIĘCEJ</ion-button></h1>
            </ion-col>
        </ion-row>
        <ion-row class="container latestnews ion-no-padding ion-no-margin">
            <ion-col v-for="item in news" :key="item.id" size="12" size-lg="4">
                <news-item :item="item"></news-item>
            </ion-col>
            <ion-col class="ion-text-center">
                <ion-button @click="this.$router.push({name: 'news.listing'})" color="greengradient" class="ion-hide-lg-up" shape="round">ZOBACZ WIĘCEJ</ion-button>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col class="ion-text-center ion-no-padding ion-no-margin"></ion-col>
        </ion-row>


    </ion-grid>

</template>

<style scoped>

.news-section{
    background-color:#fff;
    background-image: url("../../../public/assets/img/newsbg.svg");
    min-height: 819px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 5%;
}
.news-section .photo{
    width:100%;
    padding-top: 90%;
    background-size: cover;
    border:solid 23px #fff;
}
.news-section h3{
    font-size: 1.5em;
}
.news-section h3 a{
    font-weight: 600;
    color:#000;
    text-decoration: none;
}
.news-section h3 a:hover{
    color:#48a451;
    text-decoration: underline;
}
.news-section ion-col{
    --ion-grid-column-padding: 35px;
}
body.contrast .news-section{
    background-color:#000;
    background-image: none;
}
body.contrast h3 a{
    color:#fff;
}




</style>

<script>

import { IonGrid, IonRow, IonCol, IonButton } from '@ionic/vue';
import {defineComponent} from 'vue';

import {listing} from "../../api/modules/news";
import NewsItem from "../../modules/News/NewsItem";

export default defineComponent({
    components: {
        NewsItem,
        IonGrid,
        IonRow,
        IonCol,
        IonButton
    },
    data() {
        return {
            params: this.$route.params,
            news: []
        }
    },
    mounted() {
        listing(null, 3).then((response) => {
            if (response.data.status === 'success') {
                this.news = response.data.data || [];
            } else {
                alert('There was a problem with loading your content.')
            }
        });
    }
});

</script>
