import {createRouter, createWebHistory} from '@ionic/vue-router';

import CameraRouter from "./modules/CameraRouter";
import ContactRouter from "./modules/ContactRouter";
import EventRouter from "./modules/EventRouter";
import HomeRouter from "./modules/HomeRouter";
import NewsRouter from "./modules/NewsRouter";
import PlacesRouter from "./modules/PlacesRouter";
import InfrastructureRouter from "./modules/InfrastructureRouter";
import PageRouter from "./modules/PageRouter";
import TagRouter from "./modules/TagRouter";
import TicketRouter from "./modules/TicketRouter";
import MapRouter from "./modules/MapRouter";

import ErrorRouter from "./services/ErrorRouter";

/* -------------------------------------------------- */

let routes = [];

let modules = [
    ErrorRouter,
    CameraRouter,
    ContactRouter,
    EventRouter,
    NewsRouter,
    PlacesRouter,
    InfrastructureRouter,
    TagRouter,
    TicketRouter,
    HomeRouter,
    PageRouter,
    MapRouter,
]

for(const module of modules) {
    routes = routes.concat(module);
}

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes
});

export default router;
