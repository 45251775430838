import request from "../../helpers/request";

export function listing() {
    return request({
        url: `/community/listing/`,
        method: 'get',
        params: {}
    })
}

export function submit(data) {
    return request({
        url: `/contact/submit/`,
        method: 'post',
        data: data
    })
}
