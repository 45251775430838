<template>
    <ion-page>
        <ion-content>
            <ion-grid>
                <ion-row class="container">
                    <ion-col class="page-title ion-text-center" size="12">
                        <h1>Aktualności</h1>
                    </ion-col>
                </ion-row>
                <ion-row class="container">
                    <ion-col size="12" class="ion-text-center">
                        <router-link :to="'/aktualnosci/'"><ion-button :color="(this.$route.path === '/aktualnosci/' || this.$route.path === '/aktualnosci') ? 'greengradient' : 'light'" shape="round">Wszystkie</ion-button></router-link>
                        <template v-for="item in newsCategories" :key="item.id">
                            <router-link :to="`/aktualnosci/kategorie/${item.slug}`">
                                <ion-button v-if="item.items_count > 0" :color="currColor(item.slug)"  shape="round">{{ item.title }}</ion-button>
                            </router-link>
                        </template>
                        <router-link v-if="Object.keys(events).length > 0" :to="'/wydarzenia/'"><ion-button :color="(this.$route.path === '/wydarzenia/' || this.$route.path === '/wydarzenia') ? 'greengradient' : 'light'" shape="round">Wydarzenia</ion-button></router-link>
                    </ion-col>
                </ion-row>
                <ion-row class="container news-list">
                    <ion-col v-for="(item,i) in content" :key="i" size="12" size-lg="4">
                        <news-item v-if="item.item_type === 'news'" :item="item"></news-item>
                        <event-item v-if="item.price_value" :item="item"></event-item>
                    </ion-col>
                </ion-row>
            </ion-grid>
            <footer-section></footer-section>
        </ion-content>
    </ion-page>
</template>
<style scoped>
.news-list ion-col{
    --ion-grid-column-padding: 35px;
}
</style>

<script>

import {IonContent, IonGrid, IonRow, IonCol, IonButton, IonPage } from '@ionic/vue';
import {defineComponent} from 'vue';
import {listing} from "../../api/modules/news";
import {listing as eventListing} from "../../api/modules/event";
import {categories} from "../../api/modules/news";
import NewsItem from "./NewsItem";
import FooterSection from "../../sections/footerSection";
import EventItem from "@/modules/Event/EventItem";


export default defineComponent({
    name: 'NewsListing',
    components: {
        EventItem,
        FooterSection,
        NewsItem,
        IonContent,
        IonGrid,
        IonRow,
        IonCol,
        IonButton,
        IonPage
    },
    data() {
        this.setTitle('Aktualności');
        return {
            params: this.$route.params,
            content: {},
            events: {},
            newsCategories: {}
        }
    },
    methods:{
      currColor(i) {
          return (this.$route.path === ('/aktualnosci/kategorie/' + i) ? 'greengradient' : 'light');
      }
    },
    mounted() {
        Promise.all([listing(),eventListing()]).then((response) => {
            let mix = response.map((val) => {
                return val.data.data
            });
            this.content = mix[0].concat(mix[1]).sort((a,b) =>{
                if(a.created_at > b.created_at){
                    return -1
                }
                if(a.created_at < b.created_at){
                    return 1
                }
                return 0
            })
        });
        categories().then((response) => {
            if (response.data.status === 'success') {
                this.newsCategories = response.data.data || {};
            } else {
                alert('There was a problem with loading your content.')
            }
        });
    },
});

</script>
