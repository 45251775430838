import {createApp} from 'vue'
import App from './App.vue'
import Vuex from 'vuex';

import {IonicVue} from '@ionic/vue';
import VueGoogleMaps from '@fawmi/vue-google-maps';

import router from './router';
import store from './store';
import {initReqest} from './api/modules/init';
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
//import createRollbar from "@/helpers/rollbar";
import imageUrl from "@/helpers/imageHandler";
import {initPushWhenNative} from "./helpers/push";

// const rollbarPlugin = createRollbar();

const app = createApp(App)
    .use(imageUrl, {verbose: false, devMode: false, fallbackImage: 'https://via.placeholder.com/1480'})
    //.use(rollbarPlugin, {enabled: true, logLevel: 'info'})
    .use(IonicVue)
    .use(router)
    .use(store)
    .use(Vuex);



app.config.globalProperties.apiDomain = process.env.VUE_APP_API_DOMAIN;
//app.config.globalProperties.rollbar=rollbarPlugin;
/**
 * @TODO move functions below from main.js
 */
app.config.globalProperties.trimToLength = function (str, lng) {
    const stop = [' ', '.', ',', ';', '-', '/'];
    if (!str || !str.length) return '';
    if (str.length <= lng) return str;
    str = str.substr(0, lng - 2);
    while (str.length && (!stop.includes(str.substr(-1, 1)) || stop.includes(str.substr(-2, 1)))) {
        str = str.substr(0, str.length - 1);
    }
    return str.substring(0, str.length - 1) + '...';
}
app.config.globalProperties.trimToLengthFile = function (str, lng) {
    const stop = ['.', '-', '_', '/'];
    if (!str || !str.length) return '';
    if (str.length <= lng) return str;
    str = str.substr(0, lng - 2);
    while (str.length && (!stop.includes(str.substr(-1, 1)) || stop.includes(str.substr(-2, 1)))) {
        str = str.substr(0, str.length - 1);
    }
    return str.substring(0, str.length - 1) + '...';
}
app.config.globalProperties.setTitle = function (str) {
    if(str){
        document.title = str + ' - Aktywne Pogranicze';
    }
    else{
        document.title = 'Aktywne Pogranicze';
    }
    return true;
}
Promise.all([initReqest(), router.isReady()]).then((response) => {
    let opts = {
        load: {
            key: response[0].data.data.google_maps_api_key,
        }
    };
    app.use(VueGoogleMaps, opts)
    app.mount('#app');
    initPushWhenNative();
});

document.body.addEventListener('click', function (event) {
    const linkEl = event.target.closest('a');
    if (linkEl !== null) {
        if (linkEl.host === window.location.host) {
            router.push({path: linkEl.pathname});
        } else {
            window.open(linkEl.href, '_blank').focus();
        }
        event.preventDefault();
    }
});
