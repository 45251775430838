<template>
    <mobileMenu></mobileMenu>
    <desktopMenu></desktopMenu>
</template>

<script>
import {defineComponent} from 'vue';
import mobileMenu from "@/sections/mobileMenu";
import desktopMenu from "@/sections/desktopMenu";


export default defineComponent({
    components: {
        mobileMenu,
        desktopMenu
    },
});
</script>
<style>



div.ion-page-invisible {
    /* Workaround for https://forum.ionicframework.com/t/ion-page-invisible-class-not-being-removed-navigating-in-between-pages-video/162114 */
    opacity: 1 !important;
}

</style>
