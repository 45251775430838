//import {Capacitor} from '@capacitor/core';

const cropMap = {'mobile': 'mobile', 'default': 'default'};

const presets = {
    'thumb': {
        'w': 100,
        'h': 100
    },
    'main_box': {
        'h': 750
    },
};

const defaultConfig = {
    'enabled': true,
    'forceCrop': false,
    'fallbackImage': null,
    'defaultCrop': 'default',
    'verbose': null

};
const config = {};

const imageUrl = (image, params = {}) => {
    let baseObj = image['src'] ? image['src'] : image;

    let _params = params;
    if (typeof params == 'string') {
        _params = presets[params];
    }

    if (baseObj && baseObj !== []) {

        if (_params['w'])
            baseObj += `&w=${_params.w}`;
        if (_params['h'])
            baseObj += `&h=${_params.h}`;
        return baseObj;
    } else {
        config.verbose && console.debug('Cannot find image. Fallback used', image);
        return config.fallbackImage;
    }
}

//const detectCropType = () => {
//    return Capacitor.isNativePlatform() ? cropMap['mobile'] : cropMap[config.defaultCrop];
//}

const prepare = (params) => {
    Object.assign(config, defaultConfig, params);
    //config.cropType = config.forceCrop ? config.forceCrop : detectCropType();
}

const install = (app, params = {}) => {
    config.devMode = (process.env.NODE_ENV === 'development');
    config.verbose = config.devMode;
    prepare(params);

    app.config.globalProperties.$imageUrl = imageUrl
    !!config.devMode && console.debug(`imageHandler initialized as ${config.cropType} in dev mode`, config);
}
export default {install};
