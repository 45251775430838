<template>
    <ion-page>
        <ion-content>
            <section class="contact">
                <h1 class="ion-text-center ion-margin-vertical ion-padding-bottom">Kontakt</h1>
                <ion-grid class="ion-no-padding">
                    <contact-community-listing :content="content"></contact-community-listing>
                    <contact-form></contact-form>
                    <contact-map :content="content"></contact-map>

                </ion-grid>
            </section>
            <footer-section></footer-section>


        </ion-content>
    </ion-page>


</template>

<style scoped>
.contact{
    background-image:url("../../../public/assets/img/infrastructurebg.jpg");
    background-repeat: repeat-y;
    background-size:100% auto;

}

ion-row{
    --ion-grid-column-padding: 10px;
    --ion-grid-column-padding-xs: 10px;
    --ion-grid-column-padding-sm: 10px;
    --ion-grid-column-padding-md: 10px;
    --ion-grid-column-padding-lg: 10px;
    --ion-grid-column-padding-xl: 10px;
}
.community-item ion-row{
    border: solid 1px #e5e5e5;
    height: 100%;
    background-color:#fff;
    padding:40px 15px;
    color:#6c6c6c;
    font-size:0.87em;
}
.community-item a{
    color:#6c6c6c;
    text-decoration: none;
}
.community-item a:hover{
    color:#3e9355;
}
.community-item img{
    width:85px;
}
.community-item h4{
    font-size:1.4em;
    font-weight: 600;
}
.community-item table{
    margin-top:15px;
}
.community-item td:first-child{
    vertical-align: top;
    padding-right:15px;
}
.community-item td{
    padding-bottom: 5px;
    letter-spacing: 0.5px;
}
.community-item tr.address td{
    padding-bottom:38px;
}
.community-item ion-icon{
    color:#3e9355;
    font-size:1.2em
}
body.contrast .contact{
    background-color:#000;
    background-image: none;
}

</style>

<script>

import {IonPage, IonContent, IonGrid} from '@ionic/vue';
import {defineComponent} from 'vue';
import ContactForm from "./ContactForm";
import ContactMap from "./ContactMap";
import ContactCommunityListing from "./ContactCommunityListing";
import {listing} from "../../api/modules/contact";
import FooterSection from "../../sections/footerSection";

export default defineComponent({
    name: 'ContactShow',
    components: {
        FooterSection,
        ContactCommunityListing,
        ContactMap,
        ContactForm,
        IonPage,
        IonContent,
        IonGrid,

    },
    data() {
        this.setTitle('Kontakt');
        return {
            params: this.$route.params,
            content: []
        }
    },
    mounted() {
        let slug = this.params.slug;
        //let slug = (this.params.slug) ? 'przykladowa-strona' : null; // This is just for API testing, please remove it eventually.
        listing(slug).then((response) => {
            if (response.data.status === 'success') {
                this.content = response.data.data || [];
            } else {
                alert('There was a problem with loading your content.')
            }
        });
    }
});

</script>
