<template>
  <div class="vc_menustd ion-hide-lg-down">
    <ion-grid>
        <ion-row class="ion-padding-vertical ion-align-items-top container">
            <ion-col size="2">
                <ion-img :router-link="{name: 'home.show'}" src="/assets/img/logo.svg" class="logo"></ion-img>
            </ion-col>
            <ion-col size="10">
              <ion-row>
                  <ion-col size="8" class="arms">
                      <ul class="ion-no-padding ion-no-margin">
                          <template v-for="item in arms" :key="item.id">
                              <li><a :href="item.website" target="_blank" :title="`strona internetowa` + item.title"><img :src="$imageUrl(item.main_image)" :alt="item.alt" /><span>{{ item.title }}</span></a></li>
                          </template>
                      </ul>
                  </ion-col>

                  <ion-col size="4" class="ion-text-right wcagbar"><WCAG></WCAG></ion-col>
              </ion-row>
              <ion-row class="ion-margin-top">
                  <ion-col size="12">
                      <ul class="menucontainer ion-no-margin ion-no-padding ion-justify-content-end ion-padding-top">
                          <li v-for="item in mainMenu" :key="item.name">
                              <a v-if="(item.path === null) || (item.path.startsWith('http'))" :href="(item.path || '#')" :title="item.name">{{ item.name }}</a>
                              <router-link v-else :to="item.path">{{ item.name }}</router-link>
                              <template v-if="item.children.length >0">
                                  <ul class="sub">
                                      <li v-for="child in item.children" :key="child.name">
                                          <a v-if="item.path === null || item.path.startsWith('http')" :href="(child.path || '#')" :title="child.name">{{ child.name }}</a>
                                          <router-link v-else :to="child.path">{{ child.name }}</router-link>
                                      </li>
                                  </ul>
                              </template>
                          </li>
                      </ul>
                  </ion-col>
              </ion-row>

            </ion-col>

      </ion-row>
    </ion-grid>

  </div>

</template>


<style scoped>
ion-img.logo{
    max-width:239px;
    width: 100%;
    cursor: pointer;
}
.arms ul{
    display: flex;
}
.arms ul li{
    list-style-type: none;
    margin-left:5px;
    position: relative;
}
.arms a{
    display: flex;
    align-items: center;
    text-decoration: none;
}
.arms img{
    height:40px;
    margin-right: 5px;
}
.arms span{
    bottom:-25px;
    width: 0px;
    display: inline-block;
    opacity: 0;
    white-space: nowrap;
    transition: width 0.3s ease-in, opacity 0.5s ease-out;
    font-size: 0.8em;
    line-height: 1.4;
    color:#3f3f3f;
    position: absolute;
}
.arms li:hover span{
    width: 70px;
    opacity: 1;
}
ion-grid{
    padding:0;
}
ion-img{
    width: 288px;
}
.vc_menustd{
    background-image: url("../../public/assets/img/menubg.png");
    background-color:transparent;
    background-size: 100% 168px;
    background-position: top center;
    background-repeat: repeat-x;
    height: 168px;
}
.menucontainer a{
    color:#3f3f3f;
    font-size: 1em;
    text-decoration: none;
    font-weight: 300;
    letter-spacing: 1px;
    white-space: nowrap;
}
.menucontainer{
    display: flex;
    align-items: center;
}
.menucontainer li:first-child{
    margin-left:0;
}
.menucontainer li{
    list-style-type: none;
    margin-left:15px
}
.menucontainer .router-link-active{
    color: #208a6f;
}
.sub{
    position: absolute;
    top:45px;
    margin:0;
    padding: 0;
    background:var(--ion-color-greengradient);
    display: none;
}
.sub li a{
    color:#fff;
}
.sub li a.router-link-active{
    color:#fff;
    font-weight: 400;
}
.sub li{
    margin-left: 0;
    padding: 8px 10px;
    border-bottom:solid 1px #60c424;
    font-size:0.9em;
}
li:hover > .sub{
    display: block;
}
body.contrast .vc_menustd{
    background-image: none;
    background-color:#000;
}
body.contrast .menucontainer a{
    color:#fff;
}
body.contrast .arms span{
    color:#fff;
}
body.contrast .sub{
    background:#94a596;
}
body.contrast .sub li a{
    color:#000;
}
</style>

<script>
import menuResolver from "../helpers/structure.js";
import { phonePortraitOutline, searchOutline, personOutline} from 'ionicons/icons';
import WCAG from "@/sections/WCAGBar";

import {
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
} from '@ionic/vue';

import {defineComponent} from 'vue';
import {communityList} from "../api/modules/community";


export default {
    components: {
        IonGrid,
        IonRow,
        IonCol,
        IonImg,
        WCAG,
    },
    name: 'desktopMenu',
    props: ['topMenuLinks'],
    data() {
        let arms = {};
        return {
            arms,
            mainMenu: [],
        }
    },
    setup() {
        return {
            phonePortraitOutline, searchOutline, personOutline
        }
    },
    mounted: function() {
        menuResolver('main-menu').then((menu) => {
            this.mainMenu = menu;
        });
        communityList().then((response) => {
            if (response.data.status === 'success') {
                this.arms = response.data.data || {}
            } else {
                alert('problem with community list');
            }
        })
    }
}
</script>

