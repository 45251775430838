<template>
    <ion-page>
        <ion-content>


            <ion-grid>
                <ion-row class="container ion-justify-content-between ion-align-items-center">
                    <ion-col size-lg="6" size="12">
                        <p class="cat ion-no-margin" >Wydarzenia</p>
                        <h1 v-if="content.name">{{ content.title }}</h1>
                        <template v-if="content.comments">
                            <comment-box v-model:comments="content.comments"></comment-box>
                        </template>
                    </ion-col>
                    <ion-col size-lg="5" size="12">
                        <ion-row class="ion-justify-content-between ion-align-items-center navside ion-hide-lg-down" v-if="content.location">
                            <ion-col size="auto" v-if="content.location.lat"><span>Jak dojechać?</span></ion-col>
                            <ion-col size="auto" v-if="content.location.lat">
                                <ion-button color="greengradient" shape="round" @click="openMap">
                                    <ion-icon :icon="navigateCircleOutline" class="ion-margin-end"></ion-icon>
                                    Wyznacz trasę
                                    <ion-icon :icon="chevronForwardOutline" class="ion-margin-start"></ion-icon>
                                </ion-button>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                </ion-row>
                <ion-row class="container ion-justify-content-between">
                    <ion-col size-lg="6" size="12" v-if="content.main_image">
                        <template v-if="content.main_image.src">
                            <div class="photoborder">
                                <div class="photo"
                                     :style="{'background-image' : 'url(' + $imageUrl(content.main_image,{w:750,h:750}) + ')'}"
                                     @click="openImg($imageUrl(content.main_image), content.title)">
                                </div>
                            </div>
                        </template>

                    </ion-col>
                    <ion-col size-lg="5" size="12" v-if="content.location" class="ion-hide-lg-down">
                        <template v-if="content.location.lat">
                            <GMapMap

                                v-if="mapReady"
                                :center="mapMarker"
                                :zoom="7"
                                :options="{
                                   gestureHandling: 'cooperative',
                                   zoomControl: true,
                                   mapTypeControl: true,
                                   scaleControl: false,
                                   streetViewControl: false,
                                   rotateControl: false,
                                   fullscreenControl: true,
                                   disableDefaultUi: false
                                 }"
                                map-type-id="terrain"
                                class="masked"
                            >
                                <GMapCluster>
                                    <GMapMarker
                                        :key="index"
                                        :position="mapMarker"
                                        :clickable="true"
                                        :draggable="false"
                                        @click="center=mapMarker"
                                    >
                                        <GMapInfoWindow :opened="true">
                                            <h5>{{ content.title }}</h5>
                                            <h6>{{ content.location.address }}</h6>
                                        </GMapInfoWindow>
                                    </GMapMarker>
                                </GMapCluster>
                            </GMapMap>
                        </template>

                    </ion-col>
                </ion-row>
                <ion-row class="placeinfo container">
                    <ion-col size-lg="6" size="12">
                        <h1 class="ion-text-center">Informacje</h1>
                        <table class="placetable">
                            <tr v-if="content.date">
                                <td>
                                    <ion-item class="ion-no-padding" lines="none">
                                        <ion-icon :icon="callOutline"></ion-icon>
                                        Data wydarzenia:
                                    </ion-item>
                                </td>
                                <td>{{ content.event_date_simple }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <ion-item class="ion-no-padding" lines="none">
                                        <ion-icon :icon="walletOutline"></ion-icon>
                                        Cena biletu:
                                    </ion-item>
                                </td>
                                <td>{{ (content.price === false) ? 'Wstęp wolny' : (content.price_value + ' zł') }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <ion-item class="ion-no-padding" lines="none">
                                        <ion-icon :icon="peopleOutline"></ion-icon>
                                        Limit miejsc:
                                    </ion-item>
                                </td>
                                <td>{{ (content.limit === false) ? 'Bez limitu' : (content.participants_count + ' / ' + content.limit_value) }}</td>
                            </tr>
                            <tr v-if="content.location">
                                <td>
                                    <ion-item class="ion-no-padding" lines="none">
                                        <ion-icon :icon="locationOutline"></ion-icon>
                                        Adres:
                                    </ion-item>
                                </td>
                                <td v-html="content.location.address"></td>
                            </tr>
                            <tr v-if="content.owner_id">
                                <td v-if="content.owner_id.phone">
                                    <ion-item class="ion-no-padding" lines="none">
                                        <ion-icon :icon="callOutline"></ion-icon>
                                        Telefon:
                                    </ion-item>
                                </td>
                                <td v-if="content.owner_id.phone">{{ content.owner_id.phone }}</td>
                            </tr>
                            <tr v-if="content.owner_id">
                                <td v-if="content.owner_id.email">
                                    <ion-item class="ion-no-padding" lines="none">
                                        <ion-icon :icon="mailOutline"></ion-icon>
                                        E-mail:
                                    </ion-item>
                                </td>
                                <td v-if="content.owner_id.email"><a :href="'mailto:'+content.owner_id.email"
                                       :title="'mail do ' + content.owner_id.email">{{ content.owner_id.email }}</a></td>
                            </tr>
                        </table>
                        <ion-row class="ion-hide-lg-up ion-margin-top" v-if="content.location">
                            <ion-col size="12" class="ion-text-center">
                                <ion-button color="greengradient" shape="round" @click="openMap">
                                    <ion-icon :icon="navigateCircleOutline" class="ion-margin-end"></ion-icon>
                                    Wyznacz trasę
                                    <ion-icon :icon="chevronForwardOutline" class="ion-margin-start"></ion-icon>
                                </ion-button>
                            </ion-col>
                            <ion-col size="12">
                                <template v-if="mapReady">
                                    <GMapMap
                                        ref="map"
                                        :center="mapMarker"
                                        :zoom="7"
                                        :options="{
                                   gestureHandling: 'cooperative',
                                   zoomControl: true,
                                   mapTypeControl: true,
                                   scaleControl: false,
                                   streetViewControl: false,
                                   rotateControl: false,
                                   fullscreenControl: true,
                                   disableDefaultUi: false
                                 }"
                                        map-type-id="terrain"
                                        class="masked"
                                    >
                                        <GMapCluster>
                                            <GMapMarker
                                                :key="index"
                                                :position="mapMarker"
                                                :clickable="true"
                                                :draggable="false"
                                                @click="center=mapMarker"
                                            >
                                                <GMapInfoWindow :opened="true">
                                                    <h5>{{ content.title }}</h5>
                                                    <h6>{{ content.location.address }}</h6>
                                                </GMapInfoWindow>
                                            </GMapMarker>
                                        </GMapCluster>
                                    </GMapMap>
                                </template>
                            </ion-col>
                        </ion-row>


                        <template v-if="!participant.id && content.limit === true">

                            <ion-grid class="container addticket">
                                <ion-row class="ion-justify-content-around">
                                    <ion-col size="12">
                                        <h1 class="ion-text-center">Zarejestruj się na wydarzenie</h1>
                                        <ion-item>
                                            <ion-label position="floating">Imię i nazwisko</ion-label>
                                            <ion-input type="text" :value="participant.name" @input="participant.name = $event.target.value" autofocus="true" :disabled="content.participants_count === content.limit_value"></ion-input>
                                        </ion-item>
                                        <ion-item>
                                            <ion-label position="floating">Adres e-mail</ion-label>
                                            <ion-input type="email" :value="participant.email" @input="participant.email = $event.target.value" autocomplete="email" :disabled="content.participants_count === content.limit_value"></ion-input>
                                        </ion-item>
                                        <ion-item>
                                            <ion-label position="floating">Numer telefonu</ion-label>
                                            <ion-input type="number" :value="participant.phone" @input="participant.phone = $event.target.value" autocomplete="tel" :disabled="content.participants_count === content.limit_value"></ion-input>
                                        </ion-item>

                                        <ion-button type="submit" color="greengradient" expand="block" @click="submitParticipant" :disabled="content.participants_count === content.limit_value"><span v-if="content.participants_count < content.limit_value">Zarejestruj się</span><span v-else>Brak miejsc</span></ion-button>

                                    </ion-col>
                                </ion-row>
                            </ion-grid>

                        </template>
                        <ion-row v-if="participant.id">
                            <ion-col size="12">
                                <h4 class="ion-text-center success">Pomyślnie zapisałeś się na wydarzenie</h4>
                                <h1>Opłać bilet</h1>
                                <div class="bank" v-if="content.pay_bank && content.pay_bank_info">
                                    <h3>Przelew bankowy</h3>
                                    <p v-html="content.pay_bank_info.replaceAll('\n','<br/>')"></p>
                                </div>
                                <div class="bank" v-if="content.pay_cash && content.pay_cash_info">
                                    <h3>Zapłata gotówką</h3>
                                    <p v-html="content.pay_cash_info.replaceAll('\n','<br/>')"></p>
                                </div>

                            </ion-col>
                        </ion-row>

                    </ion-col>
                </ion-row>


                <ion-row class="container">
                    <ion-col size-lg="6" size="12">
                        <block-loader :blocks="content.blocks"></block-loader>
                    </ion-col>
                </ion-row>
                <ion-row class="container">
                    <ion-col size-lg="6" size="12" class="ion-text-center">
                        <ion-button color="dark" fill="outline" shape="round" class="ion-margin-vertical"
                                    @click="this.$router.push({path: '/miejsca'})">POWRÓT DO LISTY
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
            <footer-section></footer-section>
        </ion-content>

    </ion-page>



</template>
<style scoped>
h4.success{
    background:var(--ion-color-greengradient);
    padding: 15px 0;
    color: #fff;
    border-radius: 5px;
    margin: 30px 0;
}
ion-grid {
    background-image: url("../../../public/assets/img/placebg.png");
    background-position: center -104px;
    background-size: 100% auto;
    background-repeat: repeat-y;
    color: #6c6c6c;
}

p.cat {
    font-size: 0.9em;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #46a352;
    letter-spacing: 2px;
}

.navside span {
    font-family: "Just Another Hand";
    font-weight: 400;
    font-size: 2.8em;
    color: #000;
}

.navside ion-button {
    height: 46px;
}

h1 {
    font-family: "Just Another Hand";
    font-size: 3.7em;
    font-weight: 400;

}

div.vue-map-container {
    width: 100% !important;
    height: 600px !important;
}

.photo {
    height: 600px;
}

.masked {
    mask-image: url("../../../public/assets/img/maskmap.svg");
    mask-repeat: no-repeat;
    mask-size: 100%;
    mask-position: top left;
}

.placeinfo {
    margin-top: 50px;
}

.placeinfo a {
    color: #208a6f;
}

ion-item {
    color: #6c6c6c;
    --background: transparent;
}

.placeinfo ion-icon {
    color: #2a8f75;
    font-size: 1.8em;
    margin-right: 20px;

}

.placeinfo h1, .gallery h1 {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 3em;
    margin-bottom: 40px
}

.placeinfo table {
    width: 100%;
    word-wrap: anywhere;
}

.placeinfo tr {
    border-top: solid 1px #dcdddd;
}

.placeinfo tr:last-child {
    border-bottom: solid 1px #dcdddd;
}

.placeinfo td {
    padding: 10px 25px;
}

.gitem {
    padding-top: 56.25%;
    background-size: cover;
    position: relative;
    cursor: pointer;

}

.frontdesc {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    padding: 50px;
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 50%, rgba(16, 27, 42, 0.8) 100%);
    background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 50%, rgba(16, 27, 42, 0.8) 100%);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 50%, rgba(16, 27, 42, 0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#101b2a", GradientType=1);
}

.frontdesc .elwrapper {
    position: absolute;
    bottom: 50px;
}

.frontdesc:hover ~ .backdesc {
    opacity: 0.5;
}

.backdesc {
    font-size: 80%;
    color: #fff;
    font-weight: 100;
}

.backdesc {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    top: 0;
    left: 0;
    transition: .5s ease;
    background: rgb(19, 123, 48);
    background: -moz-linear-gradient(90deg, rgba(19, 123, 48, 0.7) 0%, rgba(96, 196, 36, 0.7018729967948718) 100%);
    background: -webkit-linear-gradient(90deg, rgba(19, 123, 48, 0.7) 0%, rgba(96, 196, 36, 0.7018729967948718) 100%);
    background: linear-gradient(90deg, rgba(19, 123, 48, 0.7) 0%, rgba(96, 196, 36, 0.7018729967948718) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#137b30", endColorstr="#60c424", GradientType=1);
}

@media screen and (max-width: 991px) {
    ion-grid {
        background-position: center top;
    }
    html
    .photo {
        height: auto;
    }

    div.vue-map-container {
        width: 100% !important;
        height: 300px !important;
        min-height: 400px !important;
    }

    .placeinfo td {
        padding: 15px 0;
    }
}
body.contrast ion-item, body.contrast ion-grid, body.contrast .placeinfo a, body.contrast p a{
    color: #fff !important;
}
</style>

<script>

import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from '@ionic/vue';
import {defineComponent} from 'vue';
import {show, register} from "../../api/modules/event";
import Participant from "../../models/modules/participant";
import { calendarOutline, walletOutline, peopleOutline, navigateOutline, mailOutline, callOutline, locationOutline } from "ionicons/icons";

export default defineComponent({
    name: 'EventShow',
    components: {
        IonContent,
        IonPage,
    },
    data() {
        return {
            mapReady: false,
            mapMarker: {},
            params: this.$route.params,
            content: {},
            participant: new Participant
        }
    },
    setup(){
        return{
            calendarOutline, walletOutline, peopleOutline, navigateOutline, mailOutline, callOutline, locationOutline
        }
    },
    methods: {
        submitParticipant() {
            register(
                this.params.slug,
                this.participant.getData()
            ).then((response) => {
                if (response.data.status === 'success') {
                    this.participant.setData(response.data.data || {});

                } else {
                    alert('There was a problem with loading your content.')
                }
            }).catch((exception) => {
                if(exception.response.status === 422) {
                    alert(exception.response.data.message);
                }
            });
        },
        openMap() {
            window.open('https://www.google.com/maps/dir/?api=1&destination=' + this.mapMarker.lat + ',' + this.mapMarker.lng);
        }
    },
    mounted() {
        show(this.params.slug).then((response) => {
            if (response.data.status === 'success') {
                this.content = response.data.data || {};
                this.setTitle(this.content.name);
                this.mapMarker = {
                    lat: Number(this.content.location.lat),
                    lng: Number(this.content.location.lng)
                };
                setTimeout(()=> {
                    this.mapReady = true;
                },0);
            } else {
                alert('There was a problem with loading your content.')
            }
        });
    }
});

</script>
