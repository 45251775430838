<template>

        <ion-grid v-if="feature.length" class="feature-section ion-no-padding ion-no-margin ion-padding-bottom">
            <ion-row>
                <ion-col class="ion-text-center ion-margin-bottom ion-padding-bottom">
                    <h1 class="container">Wyróżnione miejsca <ion-button @click="this.$router.push({path: '/miejsca'})" color="greengradient" class="showmore ion-hide-lg-down" shape="round">ZOBACZ WIĘCEJ</ion-button></h1>
                </ion-col>
            </ion-row>
            <ion-row class="ion-padding-bottom">
                <ion-col v-for="item in feature" :key="item.id" size="12" size-lg="4" style="position:relative;">
                    <object-item :item="item"></object-item>
                </ion-col>
                <ion-col class="ion-text-center ion-margin-top ion-hide-lg-up">
                    <ion-button @click="this.$router.push({name: 'news.listing'})" color="greengradient" shape="round">ZOBACZ WIĘCEJ</ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>

</template>

<style scoped>
.feature-section{
    background-color:#fff;
    background-image: url("../../../public/assets/img/paperbg.jpg");
    min-height: 819px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 50% 0%;
    padding:80px 0;
}
body.contrast .feature-section{
    background-image: none;
    background-color: transparent;
}
</style>

<script>

import { IonGrid, IonRow, IonCol, IonButton  } from '@ionic/vue';
import {defineComponent} from 'vue';

import {listing as featureListing} from "../../api/modules/home";
import { location, star, starOutline, starHalfOutline } from "ionicons/icons";
import ObjectItem from "../../modules/Places/PlaceItem";

export default defineComponent({
    components: {
        ObjectItem,
        IonGrid,
        IonRow,
        IonCol,
        IonButton
    },
    data() {
        return {
            params: this.$route.params,
            feature: []
        }
    },
    mounted() {
        /* realted_objects - Zobacz także */
        featureListing('featured_objects', 6).then((response) => {
            if (response.data.status === 'success') {
                this.feature = response.data.data || [];
            } else {
                alert('There was a problem with loading your content.')
            }
        });
    },
    setup(){
        return{
            location, star, starOutline, starHalfOutline
        }
    }
});

</script>
