import request from '../../helpers/request'

export function listing() {
    return request({
        url: `/event/listing/`,
        method: 'get',
        params: {}
    })
}

export function show(slug) {
    return request({
        url: `/event/show/${slug}`,
        method: 'get',
        params: {}
    })
}

export function register(slug, data) {
    return request({
        url: `/event/register/${slug}`,
        method: 'post',
        data: data
    })
}
