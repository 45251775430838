import request from '../../helpers/request'

export function listing(slug, limit = null) {
    return request({
        url: (slug) ? `/feature/listing/${slug}` : `/feature/listing/`,
        method: 'get',
        params: {
            limit: limit
        }
    })
}
