import PlacesCategories from "../../modules/Places/PlacesCategories";
import PlacesListing from "../../modules/Places/PlacesListing";
import PlaceShow from "../../modules/Places/PlaceShow";
import PlacesMap from "../../modules/Places/PlacesMap";

export default [
    {
        path: '/miejsca/kategorie/:slug',
        component: PlacesListing,
    },
    {
        path: '/miejsca/kategorie',
        component: PlacesCategories,
    },
    {
        path: '/miejsca/mapa/:slug',
        component: PlacesMap,
    },
    {
        path: '/miejsca/mapa',
        component: PlacesMap,
    },
    {
        path: '/miejsca/:slug',
        component: PlaceShow,
    },
    {
        path: '/miejsca',
        component: PlacesListing,
    }
]
