<template>
    <ion-content class="ion-padding" @click="closeModal">
        <img :src="$imageUrl(imgSrc)" :alt="title"/>
    </ion-content>
</template>
<style scoped>
ion-content {
    --background: transparent;
}
</style>
<script>
import {IonContent, modalController} from '@ionic/vue';
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'imageModal',
    props: {
        title: {type: String, default: 'Image Modal Title'},
        imgSrc: {type: String, default: 'error404'}
    },
    data() {
        return {
            content: 'Content',
        }
    },
    setup() {
        const closeModal = () => {
            modalController.dismiss()
        }
        return {
            closeModal
        }
    },
    components: {IonContent}
});
</script>
