import TagItemListing from "../../modules/Tag/TagItemListing";
import TagListing from "../../modules/Tag/TagListing";

export default [
    {
        path: '/tagi/:slug',
        name: 'tag.item.category',
        component: TagItemListing,
    },
    {
        path: '/tagi',
        name: 'tag.listing',
        component: TagListing,
    },
]
