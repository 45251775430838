import request from '../../helpers/request'
import {Capacitor} from '@capacitor/core';

const checkPlaftorm = () => {
    return Capacitor.getPlatform();
}
//const API_URL = ;

const data = {
    initUrl: process.env.VUE_APP_API_DOMAIN + '/api/hello',
    initHeaders: {
        'x-device-type': checkPlaftorm()
    },
    method: 'GET'
}

export function initReqest(endpoint) {
    return request(data)
}
