<template>

        <ion-icon name="contrast" @click="toggleContrast()"></ion-icon>
        <ion-icon src="/svg/astandard.svg" @click="toggleFont('100%')"></ion-icon>
        <ion-icon src="/svg/aplus.svg" @click="toggleFont('105%')"></ion-icon>
        <ion-icon src="/svg/aplusplus.svg" @click="toggleFont('110%')"></ion-icon>
        <ion-icon src="/svg/text-width-solid.svg" @click="toggleLetterSpacing()"></ion-icon>
        <ion-icon src="/svg/text-height-solid.svg" @click="toggleLineHeight()"></ion-icon>

</template>

<script>
import { IonIcon } from '@ionic/vue';

import { defineComponent } from 'vue';


export default defineComponent({
  components: {
    IonIcon
  },
    methods:{
      toggleContrast(){
          let body = document.querySelector('body');
          body.classList.contains('contrast') ? body.classList.remove('contrast') : body.classList.add('contrast');
      },
      toggleFont(size){
          let body = document.querySelector('body');
          body.style.fontSize = size;
      },
      toggleLetterSpacing(){
          let body = document.querySelector('body');
          (body.style.letterSpacing === '2px') ? (body.style.letterSpacing = '0px') : (body.style.letterSpacing = '2px');
      },
      toggleLineHeight(){
          let body = document.querySelector('body');
          (body.style.lineHeight === '2') ? (body.style.lineHeight = 'normal') : (body.style.lineHeight = '2');
      }
    }
});
</script>
<style scoped>
.wcagbar ion-row{
    max-width: 1590px;
    width:100%;
    margin:0 auto;
}
.wcagbar ion-icon{
    border:solid 1px #dcdcdc;
    border-radius: 50px;
    padding:10px;
    margin:0 6px;
    cursor: pointer;
    background-color: #fff;
    box-shadow: 3px 3px 7px 0px #0e806338;
    color:#208a6f;
}
.wcagbar ion-icon:nth-child(5){
    margin-left:15px
}
.wcagbar ion-icon:hover{
    background-color:#fff;
    color:#000;
}
@media screen and (max-width: 1180px){
    .wcagbar ion-icon{
        margin: 0 1px;
    }
    .wcagbar ion-icon:nth-child(5){
        margin-left:10px
    }
}
</style>
