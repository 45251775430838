import CameraListing from "../../modules/Camera/CameraListing";
import CameraShow from "../../modules/Camera/CameraShow";

export default [
    {
        path: '/kamery/:slug',
        name: 'camera.show',
        component: CameraShow,
    },
    {
        path: '/kamery',
        name: 'camera.listing',
        component: CameraListing,
    }
]
